import React, { useEffect, useState } from "react";
import Popper from "@mui/material/Popper";
import { deleteUser } from "api/resources/users";
import { Permission } from "api/models/Permission";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectUsers } from "store/selectors";
import { fetchAllUsers } from "store/appSlice";
import {
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import Table from "components/Table";
import NewUserModal from "components/Modals/NewUser";
import EditUserModal from "components/Modals/EditUser";
import ConfirmationModal from "components/Modals/Confirmation";
import { PermissionTypes } from "helpers/enums";

const UsersTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(null);

  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsers);

  type PermissionTypeKeys = keyof typeof PermissionTypes;

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, []);

  const handleDeleteUser = (id: number) => {
    deleteUser(id)
      .then((response) => {
        setConfirmationModal(null);
        dispatch(fetchAllUsers());
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      accessorKey: "email",
      header: "Email",
    },
    {
      accessorKey: "firstName",
      header: "First name",
    },
    {
      accessorKey: "lastName",
      header: "Last name",
    },
    {
      accessorKey: "permissions",
      header: "Permissions",
      cell: (row: any) => {
        return (
          <>
            {row.row.original.permissions?.map((value: Permission) => (
              <Chip
                key={`UserRoleChip-${value.id}`}
                label={PermissionTypes[value.name as PermissionTypeKeys]}
                size="small"
                sx={{ mr: 1 }}
              />
            ))}
          </>
        );
      },
    },
    {
      accessorKey: "settings",
      header: "",
      cell: (row: any) => {
        const [open, setOpen] = React.useState(false);
        const anchorRef = React.useRef<HTMLButtonElement>(null);

        const handleToggle = () => {
          setOpen((prevOpen) => !prevOpen);
        };

        const handleClose = (event: Event | React.SyntheticEvent) => {
          if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
          ) {
            return;
          }

          setOpen(false);
        };

        function handleListKeyDown(event: React.KeyboardEvent) {
          if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
          } else if (event.key === "Escape") {
            setOpen(false);
          }
        }

        // return focus to the button when we transitioned from !open -> open
        const prevOpen = React.useRef(open);
        React.useEffect(() => {
          if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
          }

          prevOpen.current = open;
        }, [open]);

        return (
          <div>
            <IconButton
              ref={anchorRef}
              id="composition-button"
              aria-controls={open ? "composition-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              size="small"
            >
              <MoreVertIcon />
            </IconButton>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="right-start"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: "right top",
                  }}
                >
                  <Paper style={{ marginRight: "1rem" }}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem
                          onClick={() => setEditModal(row.row.original)}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          onClick={() => setConfirmationModal(row.row.original)}
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Users</Typography>
        <Button
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
          onClick={() => setOpenModal(true)}
        >
          Add user
        </Button>
      </Box>
      <Table
        data={users}
        columns={columns}
        isFetching={users.length === 0}
        pageCount={1}
        page={setCurrentPage}
        searchLabel="Otsi kasutajat"
      />
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <NewUserModal
          setOpenModal={setOpenModal}
          dispatch={() => dispatch(fetchAllUsers())}
        />
      </Modal>
      <Modal open={editModal} onClose={() => setEditModal(false)}>
        <EditUserModal
          user={editModal}
          setEditModal={setEditModal}
          dispatch={() => dispatch(fetchAllUsers())}
        />
      </Modal>
      <Modal open={confirmationModal} onClose={() => setEditModal(false)}>
        <ConfirmationModal
          title={`Do you want to delete the user ${confirmationModal?.email}?`}
          subtitle="This will remove access to the system from this user. This action cannot be undone."
          buttonTitle="Delete"
          action={() => handleDeleteUser(confirmationModal.id)}
          setConfirmationModal={setConfirmationModal}
        />
      </Modal>
    </>
  );
};

export default UsersTable;
