import axiosClient from "api/axiosInstance";
import { ForecastData, ForecastTemplateData } from "api/models/Forecast";

export const getAllForecastTemplates =
  async (): Promise<ForecastTemplateData> => {
    const client = axiosClient();

    const response = await client.get(`/prognosis/templates`);

    return response.data;
  };

export const getForecastTemplate = async (
  templateId: string,
): Promise<ForecastTemplateData> => {
  const client = axiosClient();

  const response = await client.get(`/prognosis/templates/${templateId}`);

  return response.data;
};

export const createForecastTemplate = async (data: any) => {
  const client = axiosClient();

  const response = await client.post(`/prognosis/templates`, data);

  return response.data;
};

export const updateForecastTemplate = async (templateId: number, data: any) => {
  const client = axiosClient();

  const response = await client.put(`/prognosis/templates/${templateId}`, data);

  return response.data;
};

export const deleteForecastTemplate = async (templateId: number) => {
  const client = axiosClient();

  const response = await client.delete(`/prognosis/templates/${templateId}`);

  return response.data;
};

export const getAllForecasts = async (): Promise<ForecastData> => {
  const client = axiosClient();

  const response = await client.get(`/prognosis`);

  return response.data;
};

export const createForecast = async (data: any) => {
  const client = axiosClient();

  const response = await client.post(`/prognosis`, data);

  return response.data;
};

export const getForecast = async (
  forecastId: string,
): Promise<ForecastData> => {
  const client = axiosClient();

  const response = await client.get(`/prognosis/${forecastId}`);

  return response.data;
};

export const updateForecast = async (
  forecastId: string,
  data: any,
): Promise<any> => {
  const client = axiosClient();

  const response = await client.put(`/prognosis/${forecastId}`, data);

  return response.data;
};

export const confirmForecast = async (forecastId: number): Promise<any> => {
  const client = axiosClient();

  const response = await client.put(`/prognosis/${forecastId}/verify`);

  return response.data;
};

export const unConfirmForecast = async (forecastId: string): Promise<any> => {
  const client = axiosClient();

  const response = await client.put(`/prognosis/${forecastId}/unverify`);

  return response.data;
};

export const getForecastExport = async (forecastDate: string): Promise<any> => {
  const client = axiosClient();

  const response = await client.get(`/prognosis/export?date=${forecastDate}`, {
    responseType: "blob",
  });

  return response.data;
};
