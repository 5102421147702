import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchAllClientsWithFilters } from "store/appSlice";
import { Box, Button, Modal, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Table from "components/Table";
import NewClientModal from "components/Modals/NewClient";
import { ProtectedPaths } from "routes";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectClients } from "store/selectors";
import FilterComponent from "../../../../../components/FilterComponent/FilterComponent";

1;
const ClientsTable = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [filters, setFilters] = useState<{ [key: string]: string }>({});

  const dispatch = useAppDispatch();
  const clients = useAppSelector(selectClients);

  useEffect(() => {
    dispatch(fetchAllClientsWithFilters(currentPage, 25, filters));
  }, [currentPage, filters]);

  const columns = [
    {
      accessorKey: "companyName",
      header: "Name",
      cell: (row: any) => {
        const { type, companyName, firstName, lastName, id } = row.row.original;

        let displayName = "";

        if (type === "BUSINESS" && companyName !== null) {
          displayName = companyName;
        } else if (firstName !== null && lastName !== null) {
          displayName = `${firstName} ${lastName}`;
        }

        return (
          <Link to={`/${ProtectedPaths.Clients}/${id}`}>
            {displayName || "-----"}
          </Link>
        );
      },
    },
    {
      accessorKey: "eic",
      header: "EIC",
    },
    {
      accessorKey: "registryCode",
      header: "Registry code",
    },
    {
      accessorKey: "meteringPointsCount",
      header: "Metering points",
      cell: (row: any) => {
        return row.row.original.meteringPointsCount;
      },
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Clients</Typography>
        <Button
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
          onClick={() => setOpenModal(true)}
        >
          Add client
        </Button>
      </Box>
      <FilterComponent
        onFilter={setFilters}
        columns={["name", "eic", "registryCode", "meteringPoints"]}
        columnPlaceholders={["Name", "EIC", "Registry code", "Metering points"]}
      />
      <Table
        data={clients?.content}
        columns={columns}
        isFetching={!clients}
        pageCount={clients?.totalPages}
        page={setCurrentPage}
        searchLabel="Search for client"
      />
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <NewClientModal setOpenModal={setOpenModal} />
      </Modal>
    </>
  );
};

export default ClientsTable;
