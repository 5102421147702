import React from "react";
import { Box, FormControl } from "@mui/material";
import { ReportsFormValues } from "../../../../api/models/ReportsFormValues";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

interface MeteringPointsSelectProps {
  formValues: ReportsFormValues;
  setFormValues: React.Dispatch<React.SetStateAction<ReportsFormValues>>;
}

enum ContractDatesSelectType {
  CONTRACT_START = "contractStartDate",
  CONTRACT_END = "contractEndDate",
}

const ContractDatesSelect = ({
  formValues,
  setFormValues,
}: MeteringPointsSelectProps) => {
  const handleContractDatesChange = (
    value: any,
    type: ContractDatesSelectType,
  ) => {
    const newFormValues = { ...formValues };

    newFormValues[type] = value ? dayjs(value).format("YYYY-MM-DD") : null;

    setFormValues(newFormValues);
  };

  return (
    <Box display="flex" alignItems="start" mb="0.5rem">
      <Box sx={{ mr: "0.5rem" }}>
        <FormControl
          sx={{
            minWidth: 196,
            maxWidth: 196,
            background: "white",
          }}
          size="small"
        >
          <DatePicker
            slotProps={{ textField: { size: "small" } }}
            name="contractStart"
            label="Contract valid start"
            value={
              formValues.contractStartDate
                ? dayjs(formValues.contractStartDate)
                : null
            }
            onChange={(value) =>
              handleContractDatesChange(
                value,
                ContractDatesSelectType.CONTRACT_START,
              )
            }
          />
        </FormControl>
      </Box>
      <Box>
        <FormControl
          sx={{
            minWidth: 196,
            maxWidth: 196,
            background: "white",
          }}
          size="small"
        >
          <DatePicker
            slotProps={{ textField: { size: "small" } }}
            name="contractEnd"
            label="Contract valid end"
            value={
              formValues.contractEndDate
                ? dayjs(formValues.contractEndDate)
                : null
            }
            onChange={(value) =>
              handleContractDatesChange(
                value,
                ContractDatesSelectType.CONTRACT_END,
              )
            }
          />
        </FormControl>
      </Box>
    </Box>
  );
};

export default ContractDatesSelect;
