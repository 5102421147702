import React from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import CountrySelect from "components/CountrySelect";
import { Client } from "../../../../../api/models/Client";
import { CountryType } from "../../../../../api/models/CountryType";

interface ClientFormProps {
  client: Client;
  selectedCountry: CountryType;
  setSelectedCountry: React.Dispatch<React.SetStateAction<CountryType>>;
  handleSave: (event: React.FormEvent<HTMLFormElement>) => void;
}

const ClientForm: React.FC<ClientFormProps> = ({
  client,
  selectedCountry,
  setSelectedCountry,
  handleSave,
}) => {
  return (
    <>
      <Box component="form" onSubmit={handleSave} noValidate>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            mt: "3rem",
            width: "100%",
          }}
        >
          <Box>
            <Typography variant="subtitle1" sx={{ mr: "3rem" }}>
              <b>EIC:</b> {client.eic}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" sx={{ mr: "3rem" }}>
              <b>Account type:</b> {client.type}
            </Typography>
          </Box>
          {client.registryCode && (
            <Box>
              <Typography variant="subtitle1">
                <b>Registry code:</b> {client.registryCode}
              </Typography>
            </Box>
          )}
          {client.personalCode && (
            <Box>
              <Typography variant="subtitle1">
                <b>Personal code:</b> {client.personalCode}
              </Typography>
            </Box>
          )}
        </Box>
        <Grid container columnSpacing={8} sx={{ mt: "1rem", mb: "2rem" }}>
          <Grid item sm={6}>
            <Grid container spacing={2} sx={{ mt: "1rem", mb: "2rem" }}>
              <Grid item sm={12}>
                <Divider textAlign="left">Contact information</Divider>
              </Grid>
              {client.type === "BUSINESS" ? (
                <Grid item sm={12}>
                  <TextField
                    id="companyName"
                    name="companyName"
                    label="Company name"
                    size="small"
                    required
                    defaultValue={client.companyName}
                    sx={{ width: "100%" }}
                  />
                </Grid>
              ) : (
                <>
                  <Grid item sm={6}>
                    <TextField
                      id="firstName"
                      name="firstName"
                      label="First name"
                      size="small"
                      required
                      defaultValue={client.firstName}
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      id="lastName"
                      name="lastName"
                      label="Last name"
                      size="small"
                      required
                      defaultValue={client.lastName}
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                </>
              )}
              <Grid item sm={12}>
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  size="small"
                  defaultValue={client.email}
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item sm={12}>
                <TextField
                  id="phone"
                  name="phone"
                  label="Phone"
                  size="small"
                  defaultValue={client.phone}
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item sm={12}>
                <Divider textAlign="left" sx={{ mt: "1rem" }}>
                  Billing
                </Divider>
              </Grid>
              <Grid item sm={6}>
                <TextField
                  id="iban"
                  name="iban"
                  label="IBAN"
                  size="small"
                  defaultValue={client.iban}
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  id="bankName"
                  name="bankName"
                  label="Bank name"
                  size="small"
                  defaultValue={client.bankName}
                  sx={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6}>
            <Grid container spacing={2} sx={{ mt: "1rem", mb: "2rem" }}>
              <Grid item sm={12}>
                <Divider textAlign="left">Address</Divider>
              </Grid>
              <Grid item sm={12}>
                <CountrySelect
                  label="Country"
                  value={selectedCountry}
                  setValue={setSelectedCountry}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  id="state"
                  name="state"
                  label="State"
                  size="small"
                  defaultValue={client.state}
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  id="county"
                  name="county"
                  label="County"
                  size="small"
                  defaultValue={client.county}
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  id="postalCode"
                  name="postalCode"
                  label="Postal code"
                  size="small"
                  defaultValue={client.zipCode}
                  sx={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} sx={{ mt: "1rem" }}>
            <Button variant="contained" type="submit" sx={{ mr: "1rem" }}>
              Save information
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ mt: "4rem", mb: "2rem" }}></Divider>
    </>
  );
};

export default ClientForm;
