import React, { useState } from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import { ProductionParkComponentType } from "api/models/enums/ProductionParkComponentType";
import { SelectChangeEvent } from "@mui/material/Select";
import ServiceModal from "../Components/ServiceModal";
import LimitModal from "../Components/LimitModal";
import {
  removeProductionParkComponentLimit,
  removeProductionParkComponentService,
} from "api/resources/productionPark";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { fetchProductionPark } from "store/appSlice";
import { useAppDispatch } from "hooks";
import { LimitType, MaintenanceType } from "api/models/ProductionParkComponent";

interface ComponentFormProps {
  data: {
    id: number | undefined;
    type: ProductionParkComponentType;
    count: number;
    maxPower: number;
    maintenances: MaintenanceType[];
    limits: LimitType[];
  };
  handleChange: (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
    index: number,
  ) => void;
  handleRemove: (index: number) => void;
  index: number;
  productionParkId?: number;
}

const ComponentForm: React.FC<ComponentFormProps> = ({
  data,
  handleChange,
  handleRemove,
  index,
  productionParkId,
}) => {
  const dispatch = useAppDispatch();
  const [serviceModal, setServiceModal] = useState(false);
  const [limitModal, setLimitModal] = useState(false);

  const productionParkTypes = [
    { value: ProductionParkComponentType.SOLAR_PANEL, label: "Solar panel" },
    {
      value: ProductionParkComponentType.WIND_GENERATOR,
      label: "Wind generator",
    },
    { value: ProductionParkComponentType.INVERTER, label: "Inverter" },
    { value: ProductionParkComponentType.NETWORK, label: "Network" },
  ];

  const handleClearService = (serviceId: number) => {
    removeProductionParkComponentService(data.id, serviceId).then(() => {
      dispatch(fetchProductionPark(Number(productionParkId)));
    });
  };

  const handleClearLimit = (limitId: number) => {
    removeProductionParkComponentLimit(data.id, limitId).then(() => {
      dispatch(fetchProductionPark(Number(productionParkId)));
    });
  };

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12}>
        <Divider style={{ paddingBottom: 25 }} />
      </Grid>
      <Grid item xs={3}>
        <Select
          fullWidth
          label="Type"
          name="type"
          size="small"
          value={data.type}
          onChange={(e) => handleChange(e, index)}
        >
          {productionParkTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label="Max capacity (kW)"
          name="maxPower"
          size="small"
          value={data.maxPower}
          onChange={(e) => handleChange(e, index)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          size="small"
          label="Quantity"
          name="count"
          value={data.count}
          onChange={(e) => handleChange(e, index)}
        />
      </Grid>
      <Grid item xs={3}>
        <IconButton onClick={() => handleRemove(index)} sx={{ mr: 1 }}>
          <DeleteIcon />
        </IconButton>
        <Button sx={{ mr: 1 }} onClick={() => setServiceModal(true)}>
          Service
        </Button>
        <Button onClick={() => setLimitModal(true)}>Limit</Button>
      </Grid>
      <Modal open={serviceModal} onClose={() => setServiceModal(false)}>
        <ServiceModal
          setOpenModal={setServiceModal}
          maxCount={data.count}
          productionParkId={productionParkId}
          componentId={data.id}
          componentType={data.type}
        />
      </Modal>
      <Modal open={limitModal} onClose={() => setLimitModal(false)}>
        <LimitModal
          setOpenModal={setLimitModal}
          productionParkId={productionParkId}
          maxCount={data.count}
          maxCapacity={data.maxPower.toString()}
          componentId={data.id}
          componentType={data.type}
        />
      </Modal>
      {(data.limits.length > 0 || data.maintenances.length > 0) && (
        <Grid item xs={12}>
          <Grid container spacing={2} maxWidth={700}>
            {data.maintenances.length > 0 && (
              <Grid item xs={6}>
                <Typography component="p" fontWeight={600}>
                  Services
                </Typography>

                <Timeline
                  sx={{
                    paddingTop: 0,
                    marginTop: "0.4rem",
                    marginLeft: 0,
                    paddingLeft: 0,
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0,
                    },
                  }}
                >
                  {data.maintenances.map((maintenance, maintenanceIndex) => (
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot variant="outlined" color="error" />
                        {maintenanceIndex + 1 < data.maintenances.length && (
                          <TimelineConnector />
                        )}
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography
                          component="p"
                          fontWeight={600}
                        >{`${dayjs(maintenance.maintenanceFromDate).format("DD.MM.YYYY")} - ${dayjs(maintenance.maintenanceToDate).format("DD.MM.YYYY")}`}</Typography>
                        <Typography component="p">
                          {`Affected components: ${maintenance.maintenanceCount}`}
                        </Typography>
                        <Typography component="p">{`Available capacity: ${(data.count - maintenance.maintenanceCount) * data.maxPower} kW`}</Typography>
                        <Button
                          sx={{ mt: "0.4rem", mb: "1rem" }}
                          variant="outlined"
                          size="small"
                          onClick={() => handleClearService(maintenance.id)}
                        >
                          Cancel
                        </Button>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </Grid>
            )}
            {data.limits.length > 0 && (
              <Grid item xs={6}>
                <Typography component="p" fontWeight={600}>
                  Limits
                </Typography>

                <Timeline
                  sx={{
                    paddingTop: 0,
                    marginTop: "0.4rem",
                    marginLeft: 0,
                    paddingLeft: 0,
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0,
                    },
                  }}
                >
                  {data.limits.map((limit, limitIndex) => (
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot variant="outlined" color="warning" />
                        {limitIndex + 1 < data.limits.length && (
                          <TimelineConnector />
                        )}
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography
                          component="p"
                          fontWeight={600}
                        >{`${dayjs(limit.limitFromDate).format("DD.MM.YYYY")} - ${dayjs(limit.limitToDate).format("DD.MM.YYYY")}`}</Typography>
                        <Typography component="p">
                          {`Affected components: ${limit.limitCount}`}
                        </Typography>
                        <Typography component="p">{`Limited capacity: ${limit.limitMaxPower} kW`}</Typography>
                        <Typography component="p">{`Available capacity: ${(data.count - limit.limitCount) * data.maxPower + limit.limitCount * limit.limitMaxPower} kW`}</Typography>
                        <Button
                          sx={{ mt: "0.4rem", mb: "1rem" }}
                          variant="outlined"
                          size="small"
                          onClick={() => handleClearLimit(limit.id)}
                        >
                          Cancel
                        </Button>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ComponentForm;
