import React from "react";
import { Grid, TextField } from "@mui/material";
import {
  ProductionForecast,
  ProductionForecastWeights,
} from "api/models/ProductionForecast";
import { TableMatrixProduction } from "api/models/TableMatrix";
import { ProductionPark } from "api/models/ProductionPark";
import { ProductionParkType } from "api/models/enums/ProductionParkType";
import { handleWeightChange } from "./ProductionForecastService";

interface ForecastWeightsProps {
  tableMatrix: TableMatrixProduction[];
  setTableMatrix: (matrix: TableMatrixProduction[]) => void;
  globalForecastWeights: ProductionForecastWeights[];
  forecast: ProductionForecast;
  productionParks: ProductionPark[];
  setWeightChangeTrigger: (value: (prev: number) => number) => void;
  selectedPriceArea: number;
}

const ForecastWeights: React.FC<ForecastWeightsProps> = ({
  tableMatrix,
  setTableMatrix,
  globalForecastWeights,
  forecast,
  productionParks,
  setWeightChangeTrigger,
  selectedPriceArea,
}) => {
  const handleWeightChangeWrapper = (
    e: React.ChangeEvent<HTMLInputElement>,
    weightType: keyof ProductionForecastWeights,
  ) => {
    handleWeightChange(
      e,
      weightType,
      tableMatrix,
      setTableMatrix,
      globalForecastWeights,
      forecast,
      productionParks,
      setWeightChangeTrigger,
    );
  };

  const currentWeights =
    tableMatrix[selectedPriceArea]?.forecastWeights[0] || {};

  return forecast.filtersJson[0].type === ProductionParkType.SOLAR ? (
    <Grid container spacing={1} maxWidth={250}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Foreca"
          name="forecaWeight"
          size="small"
          value={
            currentWeights.forecaWeight === 0 ? "" : currentWeights.forecaWeight
          }
          type="number"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleWeightChangeWrapper(e, "forecaWeight")
          }
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Aiolos"
          name="aiolosWeight"
          size="small"
          value={
            currentWeights.aiolosWeight === 0 ? "" : currentWeights.aiolosWeight
          }
          type="number"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleWeightChangeWrapper(e, "aiolosWeight")
          }
        />
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={1} maxWidth={250}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Aiolos"
          name="aiolosWeight"
          size="small"
          value={
            currentWeights.aiolosWeight === 0 ? "" : currentWeights.aiolosWeight
          }
          type="number"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleWeightChangeWrapper(e, "aiolosWeight")
          }
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Meteologica"
          name="meteologicaWeight"
          size="small"
          value={
            currentWeights.meteologicaWeight === 0
              ? ""
              : currentWeights.meteologicaWeight
          }
          type="number"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleWeightChangeWrapper(e, "meteologicaWeight")
          }
        />
      </Grid>
    </Grid>
  );
};

export default ForecastWeights;
