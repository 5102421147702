export const searchComponent = (
  searchString: string,
  handleSearch: () => void,
) => {
  const delayInputTimeout = setTimeout(() => {
    if (searchString) {
      handleSearch();
    }
  }, 500);
  return () => clearTimeout(delayInputTimeout);
};
