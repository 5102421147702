import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { Forecast } from "../../../../api/models/Forecast";

interface ForecastDetailsProps {
  forecast: Forecast;
}

const ForecastDetails: React.FC<ForecastDetailsProps> = ({ forecast }) => {
  return (
    <Container
      maxWidth="xl"
      sx={{ background: "#f4f4f4", margin: 0, mt: "1rem", padding: "1rem" }}
    >
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Typography component="p" fontSize="1.2rem">
          {forecast.name}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Typography component="h5" fontWeight={500} sx={{ mr: "0.4rem" }}>
          Metering points:
        </Typography>
        <Typography component="p">{forecast.meteringPointsCount}</Typography>
      </Box>
    </Container>
  );
};

export default ForecastDetails;
