import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";

const axiosClient = (token: string | null = null): AxiosInstance => {
  const headers = token && {
    Authorization: `Bearer ${token}`,
  };

  const client = axios.create({
    baseURL: import.meta.env.VITE_API_PATH,
    headers,
    timeout: 60000,
    withCredentials: false,
  });

  client.interceptors.request.use((config: any) => {
    const token = localStorage.getItem("token");
    config.headers = config.headers || {};
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  client.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      try {
        const { response } = error;
        if (response?.status === 401 || response?.status === 403) {
          localStorage.removeItem("token");
          window.location.href = "/login";
        }
      } catch (e) {
        console.error(e);
      }
      throw error;
    },
  );

  return client;
};

export default axiosClient;
