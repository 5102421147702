import { useState } from "react";
import MainLayout from "layouts/Main";
import Typography from "@mui/material/Typography";
import SavedQueriesTable from "./SavedQueriesTable";

const Reports = () => {
  const [open, setOpen] = useState(false);
  const [snackbar, setSnackbar] = useState(null);

  const toggle = () => setOpen(!open);

  const snackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(null);
  };

  return (
    <MainLayout
      tabs={[
        { label: "Readings", path: "Readings" },
        { label: "Saved queries", path: "SavedQueries" },
      ]}
      snackbar={{
        open: snackbar,
        handleClose: snackbarClose,
        type: snackbar?.type,
        message: snackbar?.message,
      }}
    >
      <Typography variant="h5">Saved queries</Typography>
      <SavedQueriesTable setSnackbar={setSnackbar} />
    </MainLayout>
  );
};

export default Reports;
