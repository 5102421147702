import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { defaultStyle } from "components/Modals";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

interface ConfirmationModalType {
  title: string;
  subtitle?: string;
  confirmation?: string;
  buttonTitle: string;
  action: () => void;
  setConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmationModal = ({
  title,
  subtitle,
  confirmation,
  action,
  buttonTitle,
  setConfirmationModal,
}: ConfirmationModalType) => {
  const [checked, setChecked] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (confirmation && !checked) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [checked]);

  return (
    <Box sx={defaultStyle}>
      <Typography variant="h5" component="h5" sx={{ mb: "1rem" }}>
        {title}
      </Typography>
      {subtitle && (
        <Typography component="p" sx={{ mb: "2rem" }}>
          {subtitle}
        </Typography>
      )}
      {confirmation && (
        <FormControlLabel
          sx={{ mb: "2rem" }}
          control={<Checkbox checked={checked} onChange={handleChange} />}
          label={confirmation}
        />
      )}
      <Button
        variant="contained"
        onClick={action}
        sx={{ mr: "1rem" }}
        disabled={isDisabled}
      >
        {buttonTitle}
      </Button>
      <Button onClick={() => setConfirmationModal(null)}>Cancel</Button>
    </Box>
  );
};

export default ConfirmationModal;
