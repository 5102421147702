import { Box } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import { green, indigo, orange, pink, red } from "@mui/material/colors";
import { periods } from "helpers/periods";
import { ForecastPeriodData } from "../../../../../api/models/Forecast";

interface ForecastLineChartProps {
  periodsData?: ForecastPeriodData[];
  averageLine: number[];
  forecastLine: number[];
  meteologicaLine?: number[];
  aiolosLine?: number[];
  forecaLine?: number[];
  tableMatrix?: any;
}

interface SeriesData {
  data: number[];
  id: number;
  label: string;
  showMark: boolean;
  color?: string;
}

const xAxisData = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24,
];

const xAxis = [
  {
    data: xAxisData,
    valueFormatter: (value: number) => periods[value],
  },
];

const ForecastLineChart = ({
  averageLine,
  meteologicaLine,
  aiolosLine,
  forecaLine,
  forecastLine,
}: ForecastLineChartProps) => {
  const series: SeriesData[] = [];

  series.push({
    data: averageLine,
    id: 10,
    label: "Average",
    color: indigo[900],
    showMark: false,
  });

  series.push({
    data: forecastLine,
    id: 11,
    label: "Forecast",
    color: pink.A400,
    showMark: false,
  });

  {
    meteologicaLine.some((x) => x > 0) &&
      series.push({
        data: meteologicaLine,
        id: 12,
        label: "Meteologica",
        color: green[400],
        showMark: false,
      });
  }

  {
    aiolosLine.some((x) => x > 0) &&
      series.push({
        data: aiolosLine,
        id: 13,
        label: "Aiolos",
        color: red[900],
        showMark: false,
      });
  }

  {
    forecaLine.some((x) => x > 0) &&
      series.push({
        data: forecaLine,
        id: 14,
        label: "Foreca",
        color: orange[400],
        showMark: false,
      });
  }

  const allData = [
    ...series.flatMap((s) => s.data),
    ...averageLine,
    ...forecastLine,
  ];
  const calculatedMin = Math.min(...allData);
  const calculatedMax = Math.max(...allData);

  const yAxis = [
    {
      min: calculatedMin - 0.1,
      max: calculatedMax + 0.1,
      valueFormatter: (value: number) => value.toString(),
    },
  ];

  return (
    <Box>
      <LineChart
        xAxis={xAxis}
        yAxis={yAxis}
        series={series}
        width={530}
        height={380}
        grid={{ vertical: true, horizontal: true }}
        margin={{ left: 40, right: 40, top: 40, bottom: 90 }}
        colors={[
          "#fbb4ae",
          "#b3cde3",
          "#ccebc5",
          "#decbe4",
          "#fed9a6",
          "#ffffcc",
          "#e5d8bd",
          "#fddaec",
          "#f2f2f2",
        ]}
        slotProps={{
          legend: {
            position: {
              vertical: "bottom",
              horizontal: "middle",
            },
            itemMarkWidth: 15,
            itemMarkHeight: 2,
            markGap: 5,
            itemGap: 5,
          },
        }}
        sx={{
          "& .MuiChartsLegend-series text": { fontSize: "12px !important" },
        }}
      />
    </Box>
  );
};

export default ForecastLineChart;
