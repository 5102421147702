import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Checkbox from "@mui/material/Checkbox";
import { periods } from "helpers/periods";
import Tooltip from "@mui/material/Tooltip";
import WarningIcon from "@mui/icons-material/SyncProblem";
import { ForecastPeriodData, RowValue } from "api/models/Forecast";

import styles from "./ForecastDataGrid.module.scss";

interface ForecastDataGridProps {
  isEditable: boolean;
  periodsData: ForecastPeriodData[];
  setPeriodsData: React.Dispatch<React.SetStateAction<ForecastPeriodData[]>>;
  setAverageLine: React.Dispatch<React.SetStateAction<number[]>>;
  setForecastLine: React.Dispatch<React.SetStateAction<number[]>>;
  tableMatrix: any;
  setTableMatrix: any;
  prognoseValues: any;
}

interface TableCell {
  cellIndex: number;
  reading: number;
  isMissingData: boolean;
  meteringPointsWithConsumption: number;
  meteringPointsTotal: number;
  active: boolean;
}

export interface TableRow {
  readings: TableCell[];
  average: number;
  correction: number;
  forecast: number;
}

interface PeriodsData {
  readings: {
    selected: boolean;
    totalConsumption: number;
    isMissingData: boolean;
    meteringPointsWithConsumption: number;
    meteringPointsTotal: number;
  }[];
}

const getTableMatrix = (
  periods: string[],
  periodsData: PeriodsData[],
  prognoseValues: RowValue[],
): TableRow[] => {
  const tableData: TableRow[] = [];

  periods.map((row, rowIndex: number) => {
    const rowData: TableCell[] = [];
    let sum = 0;

    console.log("periodsData", periodsData);

    periodsData.map((col, colIndex: number) => {
      if (col.readings[rowIndex].selected) {
        sum += col.readings[rowIndex].totalConsumption;
      }

      rowData.push({
        cellIndex: rowIndex + colIndex,
        reading: col.readings[rowIndex].totalConsumption,
        isMissingData: col.readings[rowIndex].isMissingData,
        meteringPointsWithConsumption:
          col.readings[rowIndex].meteringPointsWithConsumption,
        meteringPointsTotal: col.readings[rowIndex].meteringPointsTotal,
        active: col.readings[rowIndex].selected,
      });
    });

    const correction =
      prognoseValues.length > 0
        ? prognoseValues.find((pv) => pv.hour === rowIndex)?.adjustment || 0
        : 0;

    tableData.push({
      readings: rowData,
      average:
        rowData.filter((rd) => rd.active).length > 0
          ? Math.round((sum / rowData.filter((rd) => rd.active).length) * 100) /
            100
          : 0,
      correction: correction,
      forecast:
        rowData.filter((rd) => rd.active).length > 0
          ? Number(
              (
                sum / rowData.filter((rd) => rd.active).length +
                correction
              ).toFixed(1),
            )
          : Number(correction.toFixed(1)),
    });
  });

  return tableData;
};

const ForecastDataGrid = ({
  isEditable,
  periodsData,
  setAverageLine,
  setForecastLine,
  tableMatrix,
  setTableMatrix,
  prognoseValues,
}: ForecastDataGridProps) => {
  const [isMouseDown, setIsMouseDown] = useState(false);

  const handleMouseDown = (event: any) => {
    if (event.button === 0) {
      setIsMouseDown(true);
    }
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  useEffect(() => {
    setTableMatrix(getTableMatrix(periods, periodsData, prognoseValues));
  }, [periodsData]);

  useEffect(() => {
    const avgLine: number[] = [];
    const forecastLine: number[] = [];

    tableMatrix.map((row: TableRow) => {
      avgLine.push(row.average);
      forecastLine.push(row.forecast);
    });

    setAverageLine(avgLine);
    setForecastLine(forecastLine);
  }, [tableMatrix]);

  const isCheckboxChecked = (cellIndex: number) => {
    return tableMatrix.some((row: TableRow) => row.readings[cellIndex]?.active);
  };

  const handleToggleAllPeriods = (cellIndex: number) => {
    const tableMatrixClone: TableRow[] = [...tableMatrix];
    const checkboxChecked = isCheckboxChecked(cellIndex);

    if (checkboxChecked) {
      tableMatrixClone.forEach((row) => {
        row.readings[cellIndex].active = false;
      });
    } else {
      tableMatrixClone.forEach((row) => {
        row.readings[cellIndex].active = true;
      });
    }

    setTableMatrix(tableMatrixClone);

    tableMatrixClone.forEach((_, rowIndex) => {
      calculateAvgAndForecast(rowIndex);
    });
  };

  const calculateAvgAndForecast = (rowIndex: number) => {
    const tableMatrixClone: TableRow[] = [...tableMatrix];
    let sum = 0;

    tableMatrixClone[rowIndex].readings.map((reading: TableCell) => {
      if (reading.active) {
        sum += reading.reading;
      }
    });

    const activeCount = tableMatrixClone[rowIndex].readings.filter(
      (rd: TableCell) => rd.active,
    ).length;

    const avg =
      activeCount > 0 ? Math.round((sum / activeCount) * 100) / 100 : 0;

    const forecast =
      Math.round((avg + Number(tableMatrixClone[rowIndex].correction)) * 10) /
      10;

    tableMatrixClone[rowIndex].average = avg;
    tableMatrixClone[rowIndex].forecast = forecast;
  };

  const handleSelectCell = (rowIndex: number, cellIndex: number) => {
    const tableMatrixClone = [...tableMatrix];

    tableMatrixClone[rowIndex].readings[cellIndex].active =
      !tableMatrixClone[rowIndex].readings[cellIndex].active;

    calculateAvgAndForecast(rowIndex);

    setTableMatrix(tableMatrixClone);
  };

  const handleSetCorrection = (value: number, rowIndex: number) => {
    const tableMatrixClone = [...tableMatrix];

    const average = tableMatrix[rowIndex].average;

    tableMatrix[rowIndex].correction = value;
    tableMatrix[rowIndex].forecast =
      Math.round((Number(value) + average) * 10) / 10;

    setTableMatrix(tableMatrixClone);
  };

  return (
    <div className={styles.forecastDataGrid}>
      <table
        className={classNames(styles.table, isEditable && styles.isEditing)}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        style={{ userSelect: "none" }}
      >
        <thead>
          <th className={styles.period}>Period</th>
          {periodsData.map((date, cellIndex) => (
            <th>
              <div className={styles.dateCell}>
                <p className={styles.date}>{date.date}</p>
                <p className={styles.day}>{date.day}</p>
                {isEditable && (
                  <span>
                    <Checkbox
                      size="small"
                      checked={isCheckboxChecked(cellIndex)}
                      sx={{ m: 0, p: 0 }}
                      onClick={() => handleToggleAllPeriods(cellIndex)}
                    />
                  </span>
                )}
              </div>
            </th>
          ))}
          <th>Average</th>
          <th>Correction</th>
          <th>Forecast</th>
        </thead>
        {tableMatrix.map((row: TableRow, rowIndex: number) => {
          return (
            <tr>
              <td className={styles.period}>{periods[rowIndex]}</td>
              {row.readings.map((cell, cellIndex) => (
                <td
                  className={classNames(
                    styles.data,
                    cell.active && styles.selected,
                  )}
                  onMouseDown={() => {
                    handleSelectCell(rowIndex, cellIndex);
                  }}
                  onMouseEnter={() => {
                    if (isMouseDown) {
                      handleSelectCell(rowIndex, cellIndex);
                    }
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {cell.reading}
                    {cell.isMissingData && (
                      <Tooltip
                        title={`Partial readings: ${cell.meteringPointsWithConsumption}/${cell.meteringPointsTotal}`}
                        placement="top"
                      >
                        <WarningIcon
                          sx={{
                            marginLeft: "4px",
                            width: "16px",
                            height: "16px",
                          }}
                          color="error"
                        />
                      </Tooltip>
                    )}
                  </span>
                </td>
              ))}
              <td className={styles.average}>{row.average}</td>
              <td className={classNames(styles.correction)}>
                <input
                  type="number"
                  className={styles.correctionInput}
                  value={row.correction}
                  onChange={(e) =>
                    handleSetCorrection(parseFloat(e.target.value), rowIndex)
                  }
                  step="0.1"
                ></input>
              </td>
              <td className={styles.forecast}>{row.forecast}</td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default ForecastDataGrid;
