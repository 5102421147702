import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

type TextFieldProps = {
  label: string;
  id: string;
  minWidth?: number;
  maxWidth?: number;
  disabled?: boolean;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  onChange?: any;
  type?: string;
  style?: any;
  params?: any;
  required?: boolean;
};

const TextFieldComponent = ({
  label,
  id,
  minWidth,
  maxWidth,
  type = "text",
  placeholder,
  onChange,
  value,
  defaultValue,
  disabled,
  style,
  params,
  required,
}: TextFieldProps) => {
  return (
    <FormControl
      sx={{
        minWidth: { minWidth },
        maxWidth: { maxWidth },
        background: "white",
      }}
      style={style}
      size="small"
      disabled={disabled}
    >
      <TextField
        id={id}
        label={label}
        disabled={disabled}
        size="small"
        type={type}
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        placeholder={placeholder}
        {...params}
        required={required}
      />
    </FormControl>
  );
};

export default TextFieldComponent;
