import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainLayout from "layouts/Main";
import { Box, IconButton, Typography } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ProtectedPaths } from "routes";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectClient, selectClientMeteringPoints } from "store/selectors";
import { fetchClient, fetchClientMeteringPoints } from "store/appSlice";
import {
  getClientMeteringPoints,
  loadMeteringPoints,
  updateClient,
} from "api/resources/clients";
import { fetchMeteringPointReadings } from "api/resources/meteringPoints";
import { CountryType } from "../../../../api/models/CountryType";
import MeasurementGrid from "./Components/MeasurementGrid";
import ClientForm from "./Components/ClientForm";

const EditClient = () => {
  const navigate = useNavigate();
  let { clientId } = useParams();
  const initialCountry: CountryType = { code: "", label: "", phone: "" };

  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] =
    useState<CountryType>(initialCountry);
  const [enterContractModal, setEnterContractModal] = useState(false);
  const [editContractModal, setEditContractModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15,
  });
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([]);

  const meteringPoints = useAppSelector(selectClientMeteringPoints);
  const dispatch = useAppDispatch();
  const client = useAppSelector(selectClient);

  const snackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  useEffect(() => {
    dispatch(fetchClient(clientId));
    dispatch(
      fetchClientMeteringPoints(
        clientId,
        paginationModel.page,
        paginationModel.pageSize,
      ),
    );
  }, [clientId, paginationModel]);

  useEffect(() => {
    if (client) {
      setSelectedCountry(client.country);
    }
  }, [client]);

  const handleLoadMeteringPoints = () => {
    setLoading(true);
    loadMeteringPoints(clientId).then((result) => {
      dispatch(
        fetchClientMeteringPoints(
          clientId,
          paginationModel.page,
          paginationModel.pageSize,
        ),
      );
      setLoading(false);
      setSnackbarOpen("Metering points loaded");
    });
  };

  const handleLoadMeasurementData = (loadAll = false) => {
    console.log({ rowSelectionModel });
    console.log({ meteringPoints });

    if (loadAll) {
      let allMeteringPoints = [];
      let meteringPointIds: GridRowId[] = [];

      getClientMeteringPoints(clientId, 0, client.meteringPointsCount).then(
        (res) => {
          allMeteringPoints = res.data.content;

          allMeteringPoints.map((meteringPoint) => {
            meteringPointIds.push(meteringPoint.id);
          });

          const postData = {
            meteringPointIds,
          };

          fetchMeteringPointReadings(postData).then((res) => {
            console.log({ res });

            if (res.status === 200) {
              setSnackbarOpen(
                "Readings data is loading. This could take a while.",
              );
            }
          });
        },
      );
    } else {
      const postData = {
        meteringPointIds: rowSelectionModel,
      };

      fetchMeteringPointReadings(postData).then((res) => {
        if (res.status === 200) {
          setSnackbarOpen(
            "Readings data is loading. This will take a couple of minutes.",
          );
        }
      });
    }
  };

  const handleSave = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const postData = {
      companyName: data.get("companyName"),
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      email: data.get("email"),
      phone: data.get("phone"),
      iban: data.get("iban"),
      bankName: data.get("bankName"),
      country: selectedCountry.label ? selectedCountry.label : "",
      state: data.get("state"),
      zipCode: data.get("postalCode"),
    };

    updateClient(clientId, postData)
      .then(({ data }) => {
        console.log({ data });
        setSnackbarOpen("Contact information saved");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <MainLayout
      tabs={[
        { label: "Clients", path: "Clients" },
        { label: "Metering points", path: "MeteringPoints" },
        { label: "Contracts", path: "ClientContracts" },
        { label: "Production parks", path: "ProductionParks" },
      ]}
      snackbar={{
        open: !!snackbarOpen,
        handleClose: snackbarClose,
        type: "success",
        message: snackbarOpen,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          sx={{ mr: "1rem" }}
          onClick={() => navigate(`/${ProtectedPaths.Clients}`)}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" component="h5">
          Edit client
        </Typography>
      </Box>
      {client && client.id && (
        <ClientForm
          client={client}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          handleSave={handleSave}
        />
      )}
      {client && (
        <MeasurementGrid
          rowSelectionModel={rowSelectionModel}
          setRowSelectionModel={setRowSelectionModel}
          client={client}
          loading={loading}
          handleLoadMeasurementData={handleLoadMeasurementData}
          handleLoadMeteringPoints={handleLoadMeteringPoints}
          setConfirmationModal={setConfirmationModal}
          setEditContractModal={setEditContractModal}
          setEnterContractModal={setEnterContractModal}
          meteringPoints={meteringPoints}
          clientId={clientId}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          enterContractModal={enterContractModal}
          editContractModal={editContractModal}
          confirmationModal={confirmationModal}
        />
      )}
    </MainLayout>
  );
};

export default EditClient;
