import { useEffect, useState } from "react";
import { Alert, Box, Grid, Snackbar, Typography } from "@mui/material";
import dayjs from "dayjs";
import { LineChart } from "@mui/x-charts/LineChart";
import { periods } from "helpers/periods";
import { indigo, pink } from "@mui/material/colors";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getPastProductionForecastChartData } from "api/resources/productionForecasts";
import { PreviousDayPrognoseChart } from "../../../../../../api/models/ProductionForecast";

interface PastForecastsChartProps {
  forecastDate: string;
  forecastType: string;
}

interface SeriesData {
  data: number[];
  id: number;
  label: string;
  showMark: boolean;
  color?: string;
}

const xAxisData = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24,
];

const xAxis = [
  {
    data: xAxisData,
    valueFormatter: (value: number) => periods[value],
  },
];

const PastForecastsChart = ({
  forecastDate,
  forecastType,
}: PastForecastsChartProps) => {
  const [chartData, setChartData] = useState<PreviousDayPrognoseChart | null>(
    null,
  );
  const [series, setSeries] = useState<SeriesData[] | []>([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    if (selectedDate) {
      setChartData(null);

      getPastProductionForecastChartData(
        dayjs(selectedDate).format("YYYY-MM-DD"),
        forecastType,
      )
        .then((res) => {
          setChartData(res.data);
          setSnackbarMessage("Previous day forecast fetched successfully");
          setSnackbarOpen(true);
        })
        .catch(() => {
          setSnackbarMessage("No prognose data found for the given date");
          setSnackbarOpen(true);
        });
    }
  }, [selectedDate, forecastType]);

  let yAxis: {
    min: number;
    max: number;
    valueFormatter: any;
  }[] = [];

  useEffect(() => {
    const seriesCopy = [...series];

    if (chartData) {
      if (!series.some((s) => s.id === 20)) {
        seriesCopy.push({
          data: chartData.averageLine,
          id: 20,
          label: "Average",
          color: indigo[900],
          showMark: false,
        });
      }

      if (!series.some((s) => s.id === 21)) {
        seriesCopy.push({
          data: chartData.forecastLine,
          id: 21,
          label: "Forecast",
          color: pink.A400,
          showMark: false,
        });
      }

      const allData = [
        ...seriesCopy.flatMap((s) => s.data),
        ...chartData.averageLine,
        ...chartData.forecastLine,
      ];
      const calculatedMin = Math.min(...allData);
      const calculatedMax = Math.max(...allData);

      yAxis = [
        {
          min: calculatedMin - 0.1,
          max: calculatedMax + 0.1,
          valueFormatter: (value: number) => value.toString(),
        },
      ];

      setSeries(seriesCopy);
    }
  }, [chartData]);

  return (
    <Box sx={{ mt: "2rem" }}>
      <Grid container sx={{ mb: "1rem" }}>
        <Grid item xs={6} display="flex" alignItems="center">
          <Typography component="p" fontWeight={600}>
            Past forecasts
          </Typography>
        </Grid>
        <Grid item xs={6} display="flex" alignItems="center">
          <DatePicker
            label="Forecast date"
            value={selectedDate}
            maxDate={dayjs(forecastDate).subtract(1, "day")}
            onChange={(newValue) => setSelectedDate(newValue)}
            slotProps={{ textField: { fullWidth: true, size: "small" } }}
          />
        </Grid>
      </Grid>
      {chartData && (
        <LineChart
          xAxis={xAxis}
          yAxis={yAxis}
          series={series}
          width={530}
          height={380}
          grid={{ vertical: true, horizontal: true }}
          margin={{ left: 40, right: 40, top: 40, bottom: 90 }}
          colors={[
            "#fbb4ae",
            "#b3cde3",
            "#ccebc5",
            "#decbe4",
            "#fed9a6",
            "#ffffcc",
            "#e5d8bd",
            "#fddaec",
            "#f2f2f2",
          ]}
          slotProps={{
            legend: {
              position: {
                vertical: "bottom",
                horizontal: "middle",
              },
              itemMarkWidth: 15,
              itemMarkHeight: 2,
              markGap: 5,
              itemGap: 5,
            },
          }}
          sx={{
            "& .MuiChartsLegend-series text": { fontSize: "12px !important" },
          }}
        />
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          variant="filled"
          severity={
            snackbarMessage === "Previous day forecast fetched successfully"
              ? "success"
              : "error"
          }
          sx={{ mb: "0.4rem" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PastForecastsChart;
