import { useEffect, useState } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { saveReadingsReportQuery } from "api/resources/reports";
import { defaultStyle } from "components/Modals";
import { MeteringPoints } from "../../../api/models/MeteringPoints";
import { Clients } from "../../../api/models/Clients";

interface SaveQueryModalProps {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  formValues: {
    startDate: string;
    endDate: string;
    period: string;
    readingsType: string;
    sumColumns: number;
    selectedOperator: number;
    selectedClients: any[];
    selectedMeteringPointsOptions: any[];
    contractStartDate: string;
    contractEndDate: string;
  };
}

const SaveQueryModal = ({ setOpenModal, formValues }: SaveQueryModalProps) => {
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [startDateValid, setStartDateValid] = useState(false);
  const [startDateError, setStartDateError] = useState("");
  const [endDateValid, setEndDateValid] = useState(false);
  const [endDateError, setEndDateError] = useState("");

  useEffect(() => {
    if (formValues.startDate === "") {
      setStartDateValid(false);
      setStartDateError("Start date is required.");
    } else {
      setStartDateValid(true);
      setStartDateError("");
    }

    if (formValues.endDate === "") {
      setEndDateValid(false);
      setEndDateError("End date is required.");
    } else {
      setEndDateValid(true);
      setEndDateError("");
    }
  }, [formValues.startDate, formValues.endDate]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    let selectedClients: Clients[] = [];
    let selectedMeteringPoints: MeteringPoints[] = [];

    console.log({ formValues });

    formValues.selectedClients.map((sc) => {
      selectedClients.push(sc.value);
    });

    formValues.selectedMeteringPointsOptions.map((mp) => {
      selectedMeteringPoints.push(mp.value);
    });

    const data = new FormData(event.currentTarget);
    const postData = {
      name: data.get("queryName"),
      startDate: formValues.startDate,
      endDate: formValues.endDate,
      readingsType: formValues.readingsType,
      readingsSumType: formValues.sumColumns,
      clients: selectedClients || [],
      meteringPoints: selectedMeteringPoints,
      contractStartDate: formValues.contractStartDate,
      contractEndDate: formValues.contractEndDate,
    };

    saveReadingsReportQuery(postData)
      .then((response) => {
        console.log({ response });
        setOpenModal(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box sx={defaultStyle}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography id="saveQuery" variant="h5" component="h5">
          Save query
        </Typography>
      </Box>
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2} sx={{ mt: "1rem", mb: "2rem" }}>
          <Grid item sm={8}>
            <TextField
              id="queryName"
              name="queryName"
              label="Title"
              size="small"
              onChange={(e) =>
                e.target.value !== "" ? setValid(true) : setValid(false)
              }
              required
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item sm={12} sx={{ mt: "1rem" }}>
            {startDateError && (
              <Typography
                style={{ paddingBottom: "30px" }}
                color="error"
                variant="body2"
              >
                {startDateError}
              </Typography>
            )}
            {endDateError && (
              <Typography
                style={{ paddingBottom: "30px" }}
                color="error"
                variant="body2"
              >
                {endDateError}
              </Typography>
            )}
            <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
              loadingIndicator="Loading…"
              disabled={loading || !valid || !startDateValid || !endDateValid}
            >
              Save query
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SaveQueryModal;
