import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { Client } from "api/models/Client";
import { Operator } from "api/models/Operator";
import { MeteringPoints } from "api/models/MeteringPoints";
import { ReportsOptions, ReportsQuery } from "api/models/Reports";
import { Forecast, ForecastTemplateData } from "api/models/Forecast";
import appReducer from "./appSlice";
import { User } from "api/models/User";
import { Contract } from "api/models/Contract";
import { MeteringPoint } from "api/models/MeteringPoint";
import { ProductionPark } from "api/models/ProductionPark";
import { ProductionParkComponent } from "api/models/ProductionParkComponent";
import { ForecaCoefficientsData } from "api/models/Foreca";
import { ProductionForecast } from "../api/models/ProductionForecast";

export type AppProps = {
  clients: {
    totalElements: number;
    totalPages: number;
    size: number;
    content: Client[];
  };
  client: Client;
  clientMeteringPoints: {
    totalElements: number;
    totalPages: number;
    size: number;
    content: MeteringPoint[];
  };
  users: User[];
  operators: Operator[];
  operator: Operator;
  meteringPoints: {
    totalElements: number;
    totalPages: number;
    size: number;
    content: MeteringPoints[];
  };
  contracts: {
    totalElements: number;
    totalPages: number;
    size: number;
    content: Contract[];
  };
  reportsQueryOptions: ReportsOptions;
  reportsQueries: ReportsQuery[];
  forecastTemplates: ForecastTemplateData[];
  forecasts: Forecast[];
  forecast: Forecast;
  productionPark: ProductionPark;
  productionParks: {
    totalElements: number;
    totalPages: number;
    size: number;
    content: ProductionPark[];
  };
  productionParkComponent: ProductionParkComponent;
  productionParkComponents: {
    totalElements: number;
    totalPages: number;
    size: number;
    content: ProductionParkComponent[];
  };
  productionForecast: ProductionForecast;
  productionForecasts: ProductionForecast[];
  productionForecastSelect: ProductionForecast[];
  forecaCoefficients: ForecaCoefficientsData[];
};

export type Thunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const store = configureStore({
  reducer: {
    app: appReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
