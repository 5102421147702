import axiosClient from "api/axiosInstance";
import { Users } from "api/models/Users";

export const getUsers = async (): Promise<Users> => {
  const client = axiosClient();

  const response = await client.get(`/users`);

  return response.data;
};

export const createUser = async (data: any) => {
  const client = axiosClient();

  const response = await client.post(`/users`, data);

  return response.data;
};

export const editUser = async (id: number, data: any) => {
  const client = axiosClient();

  const response = await client.put(`/users/${id}`, data);

  return response.data;
};

export const deleteUser = async (id: number) => {
  const client = axiosClient();

  const response = await client.delete(`/users/${id}`);

  return response.data;
};

export const getPermissions = async () => {
  const client = axiosClient();

  const response = await client.get(`/permissions`);

  return response.data;
};
