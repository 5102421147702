import React, { useEffect, useState } from "react";
import Popper from "@mui/material/Popper";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectOperators } from "store/selectors";
import { fetchAllOperators } from "store/appSlice";
import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import Table from "components/Table";
import NewOperatorModal from "components/Modals/NewOperator";
import EditOperatorModal from "components/Modals/EditOperator";

const OperatorsTable = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(null);

  const dispatch = useAppDispatch();
  const operators = useAppSelector(selectOperators);

  useEffect(() => {
    dispatch(fetchAllOperators());
  }, []);

  const columns = [
    {
      accessorKey: "eic",
      header: "EIC",
    },
    {
      accessorKey: "name",
      header: "Name",
    },
    {
      accessorKey: "registryCode",
      header: "Registry code",
    },
    {
      accessorKey: "settings",
      header: "",
      cell: (row: any) => {
        const [open, setOpen] = React.useState(false);
        const anchorRef = React.useRef<HTMLButtonElement>(null);

        const handleToggle = () => {
          setOpen((prevOpen) => !prevOpen);
        };

        const handleClose = (event: Event | React.SyntheticEvent) => {
          if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
          ) {
            return;
          }

          setOpen(false);
        };

        function handleListKeyDown(event: React.KeyboardEvent) {
          if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
          } else if (event.key === "Escape") {
            setOpen(false);
          }
        }

        // return focus to the button when we transitioned from !open -> open
        const prevOpen = React.useRef(open);
        React.useEffect(() => {
          if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
          }

          prevOpen.current = open;
        }, [open]);

        return (
          <div>
            <IconButton
              ref={anchorRef}
              id="composition-button"
              aria-controls={open ? "composition-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              size="small"
            >
              <MoreVertIcon />
            </IconButton>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="right-start"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: "right top",
                  }}
                >
                  <Paper style={{ marginRight: "1rem" }}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem
                          onClick={() => setEditModal(row.row.original)}
                        >
                          Edit
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Operators</Typography>
        <Button
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
          onClick={() => setOpenModal(true)}
        >
          Add operator
        </Button>
      </Box>
      <Table
        data={operators}
        columns={columns}
        isFetching={!operators}
        pageCount={1}
        page={setCurrentPage}
        searchLabel="Otsi kasutajat"
      />
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <NewOperatorModal
          setOpenModal={setOpenModal}
          dispatch={() => dispatch(fetchAllOperators())}
        />
      </Modal>
      <Modal open={editModal} onClose={() => setEditModal(false)}>
        <EditOperatorModal
          operator={editModal}
          setEditModal={setEditModal}
          dispatch={() => dispatch(fetchAllOperators())}
        />
      </Modal>
    </>
  );
};

export default OperatorsTable;
