import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { fetchForecaCoefficents } from "store/appSlice";
import { selectForecaCoefficents } from "store/selectors";
import { updateForecaCoefficents } from "api/resources/foreca";

interface Snackbar {
  type: string;
  message: string;
}

interface ForecaTableProps {
  setSnackbar: (snackbar: Snackbar) => void;
}

const ForecaTable: React.FC<ForecaTableProps> = ({ setSnackbar }) => {
  const [forecaCoefficentValues, setForecaCoefficentValues] = useState([]);
  const forecaCoefficents = useAppSelector(selectForecaCoefficents);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchForecaCoefficents());
  }, [dispatch]);

  useEffect(() => {
    setForecaCoefficentValues(forecaCoefficents);
  }, [forecaCoefficents]);

  const handleSaveForecaCoefficients = () => {
    updateForecaCoefficents(forecaCoefficentValues)
      .then((res) => {
        setSnackbar({
          type: "success",
          message: "Coefficients saved",
        });
      })
      .catch((err) => {
        setSnackbar({
          type: "error",
          message: "Something went wrong",
        });
      });
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Foreca coefficents</Typography>
        <Button
          variant="contained"
          onClick={() => handleSaveForecaCoefficients()}
        >
          Save
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ maxWidth: 600, mt: 4 }}>
        <Table sx={{ maxWidth: 600 }} size="small">
          <TableHead sx={{ bgcolor: "#eeeeee" }}>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Symbol</TableCell>
              <TableCell>Coefficient</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {forecaCoefficentValues &&
              [...forecaCoefficentValues]
                .sort(function (a, b) {
                  if (a.code.toLowerCase() < b.code.toLowerCase()) return -1;
                  if (a.code.toLowerCase() > b.code.toLowerCase()) return 1;
                  return 0;
                })
                .map((row) => (
                  <TableRow key={row.code}>
                    <TableCell component="th" scope="row">
                      {row.code}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.symbol}
                    </TableCell>
                    <TableCell>
                      <TextField
                        id="coefficient"
                        type="number"
                        variant="standard"
                        size="small"
                        value={row.coefficient}
                        onChange={(e) => {
                          const forecaCoefficentValuesCopy = [
                            ...forecaCoefficentValues,
                          ];

                          const index = forecaCoefficentValuesCopy.findIndex(
                            (f) => f.code === row.code,
                          );

                          forecaCoefficentValuesCopy[index] = {
                            code: row.code,
                            symbol: row.symbol,
                            coefficient: e.target.value,
                          };

                          setForecaCoefficentValues(forecaCoefficentValuesCopy);
                        }}
                        sx={{ maxWidth: "140px" }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ForecaTable;
