import React from "react";
import { Box, Container } from "@mui/material";

import { Forecast, ForecastPeriodData } from "../../../../api/models/Forecast";
import ForecastDataGrid from "../ManageSamples/EditSample/ForecastDataGrid";
import ForecastLineChart from "../ManageSamples/EditSample/ForecastLineChart";
import { TableMatrixProduction } from "../../../../api/models/TableMatrix";

interface MainContentProps {
  periodsData: ForecastPeriodData[];
  setPeriodsData: React.Dispatch<React.SetStateAction<ForecastPeriodData[]>>;
  setAverageLine: React.Dispatch<React.SetStateAction<number[]>>;
  setForecastLine: React.Dispatch<React.SetStateAction<number[]>>;
  tableMatrix: TableMatrixProduction[];
  setTableMatrix: React.Dispatch<React.SetStateAction<TableMatrixProduction[]>>;
  isEditable: boolean;
  forecast: Forecast;
  averageLine: number[];
  forecastLine: number[];
}

const MainContent: React.FC<MainContentProps> = ({
  periodsData,
  setPeriodsData,
  setAverageLine,
  setForecastLine,
  tableMatrix,
  setTableMatrix,
  isEditable,
  forecast,
  averageLine,
  forecastLine,
}) => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        background: "white",
        mt: "1rem",
        padding: "1rem",
        boxShadow: 2,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        {periodsData && (
          <Box>
            <ForecastDataGrid
              prognoseValues={forecast?.prognoseValues}
              periodsData={periodsData}
              setPeriodsData={setPeriodsData}
              setAverageLine={setAverageLine}
              setForecastLine={setForecastLine}
              tableMatrix={tableMatrix}
              setTableMatrix={setTableMatrix}
              isEditable={isEditable}
            />
          </Box>
        )}

        <Box sx={{ position: "sticky", top: "4rem" }}>
          <ForecastLineChart
            periodsData={periodsData}
            averageLine={averageLine}
            forecastLine={forecastLine}
            tableMatrix={tableMatrix}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default MainContent;
