import d000 from "./d000.svg";
import d100 from "./d100.svg";
import d200 from "./d200.svg";
import d300 from "./d300.svg";
import d400 from "./d400.svg";
import d500 from "./d500.svg";
import d600 from "./d600.svg";
import d210 from "./d210.svg";
import d310 from "./d310.svg";
import d410 from "./d410.svg";
import d220 from "./d220.svg";
import d320 from "./d320.svg";
import d420 from "./d420.svg";
import d430 from "./d430.svg";
import d240 from "./d240.svg";
import d340 from "./d340.svg";
import d440 from "./d440.svg";
import d211 from "./d211.svg";
import d311 from "./d311.svg";
import d411 from "./d411.svg";
import d221 from "./d221.svg";
import d321 from "./d321.svg";
import d421 from "./d421.svg";
import d431 from "./d431.svg";
import d212 from "./d212.svg";
import d312 from "./d312.svg";
import d412 from "./d412.svg";
import d222 from "./d222.svg";
import d322 from "./d322.svg";
import d422 from "./d422.svg";
import d432 from "./d432.svg";

import n000 from "./n000.svg";
import n100 from "./n100.svg";
import n200 from "./n200.svg";
import n300 from "./n300.svg";
import n400 from "./n400.svg";
import n500 from "./n500.svg";
import n600 from "./n600.svg";
import n210 from "./n210.svg";
import n310 from "./n310.svg";
import n410 from "./n410.svg";
import n220 from "./n220.svg";
import n320 from "./n320.svg";
import n420 from "./n420.svg";
import n430 from "./n430.svg";
import n240 from "./n240.svg";
import n340 from "./n340.svg";
import n440 from "./n440.svg";
import n211 from "./n211.svg";
import n311 from "./n311.svg";
import n411 from "./n411.svg";
import n221 from "./n221.svg";
import n321 from "./n321.svg";
import n421 from "./n421.svg";
import n431 from "./n431.svg";
import n212 from "./n212.svg";
import n312 from "./n312.svg";
import n412 from "./n412.svg";
import n222 from "./n222.svg";
import n322 from "./n322.svg";
import n422 from "./n422.svg";
import n432 from "./n432.svg";

export default {
  d000,
  d100,
  d200,
  d300,
  d400,
  d500,
  d600,
  d210,
  d310,
  d410,
  d220,
  d320,
  d420,
  d430,
  d240,
  d340,
  d440,
  d211,
  d311,
  d411,
  d221,
  d321,
  d421,
  d431,
  d212,
  d312,
  d412,
  d222,
  d322,
  d422,
  d432,
  n000,
  n100,
  n200,
  n300,
  n400,
  n500,
  n600,
  n210,
  n310,
  n410,
  n220,
  n320,
  n420,
  n430,
  n240,
  n340,
  n440,
  n211,
  n311,
  n411,
  n221,
  n321,
  n421,
  n431,
  n212,
  n312,
  n412,
  n222,
  n322,
  n422,
  n432,
};
