import axiosClient from "api/axiosInstance";
import { Operator } from "api/models/Operator";
import { Operators } from "api/models/Operators";

export const createOperator = async (data: any) => {
  const client = axiosClient();

  const response = await client.post(`/operators`, data);

  return response.data;
};

export const getOperators = async (): Promise<Operators> => {
  const client = axiosClient();

  const response = await client.get(`/operators`);

  return response.data;
};

export const updateOperator = async (
  operatorId: string,
  data: any
): Promise<Operator> => {
  const client = axiosClient();

  const response = await client.put(`/operators/${operatorId}`, data);

  return response.data;
};
