import axiosClient from "api/axiosInstance";
import { ClientData, Clients } from "api/models/Clients";

export const getClients = async (
  page: number,
  size: number,
  filters: { [key: string]: string } = {},
  query?: string,
): Promise<Clients> => {
  const client = axiosClient();

  const queryParams = new URLSearchParams({
    page: page.toString(),
    size: size.toString(),
    ...filters,
  }).toString();

  const stringQuery = query ? "&query=" + query : "";

  const response = await client.get(`/clients?${queryParams}${stringQuery}`);

  return response.data;
};

export const getClient = async (clientId: string): Promise<Clients> => {
  const client = axiosClient();

  const response = await client.get(`/clients/${clientId}`);

  return response.data;
};

export const updateClient = async (
  clientId: string,
  data: any,
): Promise<Clients> => {
  const client = axiosClient();

  const response = await client.put(`/clients/${clientId}`, data);

  return response.data;
};

export const findClient = async (data: any): Promise<ClientData> => {
  const client = axiosClient();

  const response = await client.post(`/clients/find`, data);

  return response.data;
};

export const loadMeteringPoints = async (
  clientId: string,
): Promise<Clients> => {
  const client = axiosClient();

  const response = await client.get(
    `/clients/${clientId}/load-metering-points`,
  );

  return response.data;
};

export const getClientMeteringPoints = async (
  clientId: string,
  page: number,
  size: number,
): Promise<Clients> => {
  const client = axiosClient();

  const response = await client.get(
    `/clients/${clientId}/metering-points?page=${page}&size=${size}`,
  );

  return response.data;
};
