import { useState } from "react";
import MainLayout from "layouts/Main";
import { Box } from "@mui/material";
import ForecaTable from "./ForecaTable";

const ForecaSettings = () => {
  const [snackbar, setSnackbar] = useState(null);

  const snackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(null);
  };

  return (
    <MainLayout
      tabs={[
        { label: "Users", path: "Users" },
        { label: "Operators", path: "Operators" },
        { label: "Litgrid", path: "Litgrid" },
        { label: "Foreca", path: "ForecaSettings" },
        { label: "Elevation coefficents", path: "ElevationCoefficents" },
      ]}
      snackbar={{
        open: !!snackbar,
        handleClose: snackbarClose,
        type: snackbar?.type,
        message: snackbar?.message,
      }}
    >
      <Box display="flex" justifyContent="space-between" sx={{ mt: 4 }}>
        <Box sx={{ flex: 1, mr: 8 }}>
          <ForecaTable setSnackbar={snackbar} />
        </Box>
      </Box>
    </MainLayout>
  );
};
export default ForecaSettings;
