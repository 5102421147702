import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "provider/authProvider";
import { ProtectedPaths, UnprotectedPaths } from "routes";
import React from "react";

export const ProtectedRoute: React.FC = () => {
  const { token } = useAuth();
  const location = useLocation();

  // Check if the user is authenticated
  if (!token) {
    // If not authenticated, redirect to the login page
    return <Navigate to={`/${UnprotectedPaths.Login}`} />;
  }

  if (location.pathname === "/") {
    return <Navigate to={`/${ProtectedPaths.Readings}`} />;
  }

  // If authenticated, render the child routes
  return <Outlet />;
};
