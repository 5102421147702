import { Box, Button, Grid, Modal, Stack, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import MeasurementPoints from "../MeasurementPoints";
import EnterContractModal from "../../../../../components/Modals/EnterContract";
import EditContractModal from "../../../../../components/Modals/EditContract";
import ConfirmationModal from "../../../../../components/Modals/Confirmation";
import React from "react";
import { GridRowId } from "@mui/x-data-grid";
import { Client } from "../../../../../api/models/Client";
import { MeteringPoint } from "../../../../../api/models/MeteringPoint";

interface MeasurementGridProps {
  rowSelectionModel: GridRowId[];
  setRowSelectionModel: React.Dispatch<React.SetStateAction<GridRowId[]>>;
  client: Client;
  loading: boolean;
  handleLoadMeasurementData: (loadAll?: boolean) => void;
  handleLoadMeteringPoints: () => void;
  setConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
  setEditContractModal: React.Dispatch<React.SetStateAction<boolean>>;
  setEnterContractModal: React.Dispatch<React.SetStateAction<boolean>>;
  meteringPoints: {
    totalElements: number;
    totalPages: number;
    size: number;
    content: MeteringPoint[];
  };
  clientId: string;
  paginationModel: { page: number; pageSize: number };
  setPaginationModel: React.Dispatch<React.SetStateAction<any>>;
  enterContractModal: boolean;
  editContractModal: boolean;
  confirmationModal: boolean;
}

const MeasurementGrid: React.FC<MeasurementGridProps> = ({
  rowSelectionModel,
  setRowSelectionModel,
  client,
  loading,
  handleLoadMeasurementData,
  handleLoadMeteringPoints,
  setConfirmationModal,
  setEditContractModal,
  setEnterContractModal,
  meteringPoints,
  clientId,
  paginationModel,
  setPaginationModel,
  enterContractModal,
  editContractModal,
  confirmationModal,
}) => {
  return (
    <Grid container columnSpacing={8} sx={{ mb: "2rem" }}>
      <Grid item sm={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: "1rem",
          }}
        >
          <Typography variant="h5" component="h5">
            Metering points
          </Typography>
          <Stack direction="row" spacing={2}>
            {rowSelectionModel.length === 0 && (
              <LoadingButton
                size="small"
                onClick={() => setConfirmationModal(true)}
                variant={
                  client.meteringPointsCount > 0 ? "outlined" : "contained"
                }
                loading={loading}
                loadingIndicator="Loading…"
                disabled={loading}
              >
                Load metering points
              </LoadingButton>
            )}
            {client.meteringPointsCount > 0 &&
              rowSelectionModel.length === 0 && (
                <Button
                  size="small"
                  variant={
                    client.meteringPointsCount > 0 ? "contained" : "outlined"
                  }
                  onClick={() => handleLoadMeasurementData(true)}
                >
                  Load all measurement data
                </Button>
              )}
            {client.meteringPointsCount > 0 && rowSelectionModel.length > 0 && (
              <Button
                size="small"
                variant="contained"
                onClick={() => handleLoadMeasurementData()}
              >
                Load measurement data
              </Button>
            )}
            {rowSelectionModel.length > 0 && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => setEditContractModal(true)}
              >
                Edit contract end date
              </Button>
            )}
            {rowSelectionModel.length > 0 && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => setEnterContractModal(true)}
              >
                Enter contract
              </Button>
            )}
            {/* <Button size="small" variant="outlined">
                    Edit contract
                  </Button> */}
          </Stack>
        </Box>
        {meteringPoints?.content.length > 0 ? (
          <MeasurementPoints
            clientId={clientId}
            meteringPoints={meteringPoints}
            rowSelectionModel={rowSelectionModel}
            setRowSelectionModel={setRowSelectionModel}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
          />
        ) : (
          <Typography variant="subtitle1" sx={{ mt: "2rem" }}>
            No metering points
          </Typography>
        )}
      </Grid>
      <Modal
        open={enterContractModal}
        onClose={() => setEnterContractModal(false)}
      >
        <EnterContractModal
          client={client}
          selectedMeteringPoints={rowSelectionModel}
          setSelectedMeteringPoints={setRowSelectionModel}
          setOpenModal={setEnterContractModal}
        />
      </Modal>
      <Modal
        open={editContractModal}
        onClose={() => setEditContractModal(false)}
      >
        <EditContractModal
          client={client}
          startDate={"2024-05-01"}
          selectedMeteringPoints={rowSelectionModel}
          setSelectedMeteringPoints={setRowSelectionModel}
          setOpenModal={setEnterContractModal}
        />
      </Modal>
      <Modal
        open={confirmationModal}
        onClose={() => setConfirmationModal(false)}
      >
        <ConfirmationModal
          title={`Load metering points`}
          confirmation="I confirm that I have the authority to load the customer's metering points."
          buttonTitle="Load metering points"
          action={() => {
            handleLoadMeteringPoints();
            setConfirmationModal(false);
          }}
          setConfirmationModal={setConfirmationModal}
        />
      </Modal>
    </Grid>
  );
};

export default MeasurementGrid;
