import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "provider/authProvider";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import SnackbarContent from "@mui/material/SnackbarContent";
import { ProtectedPaths } from "routes";

export default function SignIn() {
  const { setToken } = useAuth();
  const navigate = useNavigate();
  const [formError, setFormError] = useState(null);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const userData = {
      email: data.get("email"),
      password: data.get("password"),
    };

    axios
      .post(`${import.meta.env.VITE_API_PATH}/auth/login`, userData)
      .then((response) => {
        setToken(response.data.data.accessToken);
        navigate(`/${ProtectedPaths.Readings}`, { replace: true });
      })
      .catch((error) => {
        console.log(error);
        setFormError(error.response.data.message);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Logi sisse
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Parool"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {formError && (
            <Box sx={{ mt: 2, mb: 2 }}>
              <Alert variant="filled" severity="error">
                {formError}
              </Alert>
            </Box>
          )}
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Jäta mind meelde"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Logi sisse
          </Button>
          <Grid container>
            <Grid item xs></Grid>
            <Grid item>
              <Link href="/register" variant="body2">
                Loo kasutaja
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
