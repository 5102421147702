import React from "react";
import classNames from "classnames";
import styles from "../../EditSample/ForecastDataGrid.module.scss";
import { grey } from "@mui/material/colors";
import { TableMatrixProduction } from "api/models/TableMatrix";

interface FixedTableProps {
  tableMatrix: TableMatrixProduction[];
  selectedPriceArea: number;
  isEditable: boolean;
  handleMouseDown: (event: any) => void;
  handleMouseUp: () => void;
  handleSetCorrection: (value: number, rowIndex: number) => void;
  hardcodedPeriods: string[];
}

const FixedTable: React.FC<FixedTableProps> = ({
  tableMatrix,
  selectedPriceArea,
  isEditable,
  handleMouseDown,
  handleMouseUp,
  handleSetCorrection,
  hardcodedPeriods,
}) => {
  return (
    <div className={styles.forecastDataGrid}>
      <table
        className={classNames(styles.table, isEditable && styles.isEditing)}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        style={{
          border: "solid",
          borderWidth: 1,
          borderTop: 0,
          borderBottom: 0,
          borderLeft: 0,
          borderColor: grey[400],
        }}
      >
        <thead>
          <tr style={{ background: grey[200] }}>
            <th>Summary</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th>Period</th>
            <th>Total</th>
            <th>Correction</th>
            <th>Forecast</th>
          </tr>
        </thead>
        <tbody>
          {tableMatrix.length > 0 &&
            hardcodedPeriods.map((period, rowIndex) => (
              <tr key={rowIndex}>
                <td className={`${styles.period} ${styles.sticky}`}>
                  {period}
                </td>
                <td className={`${styles.average} ${styles.sticky}`}>
                  {tableMatrix[selectedPriceArea] &&
                  tableMatrix[selectedPriceArea].periods[rowIndex]
                    ? tableMatrix[selectedPriceArea].periods[
                        rowIndex
                      ].total.toFixed(2)
                    : "N/A"}
                </td>
                <td className={`${styles.correction} ${styles.sticky}`}>
                  <input
                    type="number"
                    className={styles.correctionInput}
                    onChange={(e) =>
                      handleSetCorrection(parseFloat(e.target.value), rowIndex)
                    }
                    value={
                      tableMatrix[selectedPriceArea].periods[rowIndex]
                        .adjustment
                    }
                    step="0.1"
                  />
                </td>
                <td className={`${styles.forecast} ${styles.sticky}`}>
                  {tableMatrix[selectedPriceArea].periods[
                    rowIndex
                  ].forecast.toFixed(2)}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default FixedTable;
