import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Container, Stack } from "@mui/material";
import { getForecastTemplate } from "../../../api/resources/forecasts";
import CustomDateSection from "./Components/CustomDateSection";
import MeteringPointsSelection from "./Components/MeteringPointsSelection";
import { LoadingButton } from "@mui/lab";
import { isNumberArray } from "./util/helpers";
import { ForecastFormValuesProps } from "../../../api/models/Forecast";
import ClientSelection from "./Components/ClientSelection";

interface FiltersProps {
  noCustomDates?: boolean;
  formValues: ForecastFormValuesProps;
  setFormValues: React.Dispatch<React.SetStateAction<any>>;
  handleSubmit: (e: any) => void;
  submitText?: string;
  loading: boolean;
  handleCancel?: () => void;
  cancelText?: string;
  showButtons?: boolean;
}

const Filters = ({
  noCustomDates,
  formValues,
  setFormValues,
  handleSubmit,
  submitText,
  loading,
  handleCancel,
  cancelText,
  showButtons,
}: FiltersProps) => {
  const [selectedClients, setSelectedClients] = useState(
    isNumberArray(formValues.selectedClients)
      ? formValues.selectedClients.map((value) => ({ value, title: "" }))
      : (formValues.selectedClients as { title: string; value: number }[]),
  );
  const [selectedMeteringPointsOptions, setSelectedMeteringPointsOptions] =
    useState(
      isNumberArray(formValues.selectedMeteringPointsOptions)
        ? formValues.selectedMeteringPointsOptions.map((value) => ({ value }))
        : formValues.selectedMeteringPointsOptions,
    );
  const [customDate, setCustomDate] = useState(null);

  const { sampleId } = useParams();

  useEffect(() => {
    if (sampleId) {
      getForecastTemplate(sampleId)
        .then((response) => {
          const template = response.data;
          const initialClients = template.filters[0].clients.map((client) => ({
            value: client.id,
            title:
              client.companyName || `${client.firstName} ${client.lastName}`,
          }));
          setSelectedClients(initialClients);

          setSelectedMeteringPointsOptions(
            template.filters[0].meteringPoints.map((mp) => ({
              value: mp.id,
              title: mp.eic,
            })),
          );
        })
        .catch((err) => console.log(err));
    }
  }, [sampleId]);

  return (
    <Box component="form" onSubmit={(e) => handleSubmit(e)} noValidate>
      {!noCustomDates && (
        <CustomDateSection
          customDate={customDate}
          setCustomDate={setCustomDate}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      )}
      <Container
        maxWidth="md"
        sx={{
          background: "#f4f4f4",
          margin: 0,
          mt: "1rem",
          padding: "1rem",
        }}
      >
        <Box display="flex" alignItems="start" mb="0.5rem">
          <ClientSelection
            selectedClients={selectedClients}
            setSelectedClients={setSelectedClients}
            formValues={formValues}
            setFormValues={setFormValues}
          />
          <MeteringPointsSelection
            selectedMeteringPointsOptions={selectedMeteringPointsOptions}
            setSelectedMeteringPointsOptions={setSelectedMeteringPointsOptions}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        </Box>
      </Container>
      {showButtons && (
        <Stack spacing={2} direction="row" style={{ marginTop: "1rem" }}>
          <LoadingButton
            type="submit"
            size="small"
            variant="contained"
            loading={loading}
            loadingIndicator="Loading..."
            disabled={loading}
          >
            {submitText}
          </LoadingButton>
          {handleCancel && (
            <Button variant="text" onClick={() => handleCancel()}>
              {cancelText}
            </Button>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default Filters;
