import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "provider/authProvider";
import { ProtectedPaths } from "routes";

type Props = { children: React.ReactNode };

export const UnProtectedRoute: React.FC<Props> = ({ children }) => {
  const { token } = useAuth();

  // Check if the user is authenticated
  if (token) {
    // If not authenticated, redirect to the login page
    return <Navigate to={`/${ProtectedPaths.Readings}`} />;
  }

  // If authenticated, render the child routes
  return children;
};
