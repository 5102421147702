import dayjs from "dayjs";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const ElevationTable = () => {
  const columns = Array.from(Array(24).keys());

  const getDates = (from: string, to: string) => {
    const cFrom = new Date(from);
    const cTo = new Date(to);

    let daysArr = [new Date(cFrom)];
    let tempDate = cFrom;

    while (tempDate < cTo) {
      tempDate.setUTCDate(tempDate.getUTCDate() + 1);
      daysArr.push(new Date(tempDate));
    }

    return daysArr;
  };

  const rows = getDates("01.01.2024", "12.31.2024");

  return (
    <TableContainer component={Paper} sx={{ maxHeight: "1000px" }}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ bgcolor: "#eeeeee", borderBottom: "1px solid #a7a7a7" }}
            >
              Date
            </TableCell>
            {columns.map((col, colIndex) => (
              <TableCell
                sx={{
                  bgcolor: "#eeeeee",
                  borderBottom: "1px solid #a7a7a7",
                }}
              >
                {dayjs().hour(col).minute(0).format("HH:mm")}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => {
            return (
              <TableRow
                key={rowIndex}
                sx={{ "&:hover": { background: "#efefef" } }}
              >
                <TableCell
                  sx={{
                    background: "#eee",
                    position: "sticky",
                    left: "0",
                    fontWeight: "500",
                    borderRight: "1px solid #a7a7a7",
                  }}
                >
                  {dayjs(row).format("DD.MM.YYYY")}
                </TableCell>
                {columns.map((col, colIndex) => (
                  <TableCell>
                    <Typography component="p" fontSize="12px">
                      0.142352
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default ElevationTable;
