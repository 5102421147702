import React from "react";
import classNames from "classnames";
import { grey } from "@mui/material/colors";
import styles from "../../EditSample/ForecastDataGrid.module.scss";
import { hardcodedPeriods } from "../mockData";

interface OverallTableProps {
  overallValues: any[];
  selectedPriceArea: number;
  availableForecasts: { [key: string]: string[] };
  forecast: any;
}

const OverallTable: React.FC<OverallTableProps> = ({
  overallValues,
  selectedPriceArea,
  availableForecasts,
  forecast,
}) => {
  const forecastTypeMapping: { [key: string]: string } = {
    foreca: "forecaData",
    aiolos: "aiolosData",
    meteologica: "meteologicaData",
  };

  return (
    <div className={styles.forecastDataGrid}>
      <table
        className={classNames(styles.table)}
        style={{
          border: "solid",
          borderWidth: 1,
          borderRight: "1px solid",
          borderLeft: 0,
          borderTop: 0,
          borderBottom: 0,
          borderColor: grey[400],
          borderCollapse: "collapse",
          userSelect: "none",
        }}
      >
        <thead>
          <tr style={{ background: grey[200] }}>
            <th>Overall</th>
            <th></th>
          </tr>
          <tr>
            {availableForecasts[forecast.filtersJson[0].type].map(
              (forecastType: string) => (
                <th key={forecastType} style={{ textTransform: "capitalize" }}>
                  {forecastType}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {hardcodedPeriods.map((_, rowIndex) => (
            <tr key={rowIndex}>
              {availableForecasts[forecast.filtersJson[0].type].map(
                (forecastType: string) => {
                  const dataKey = forecastTypeMapping[forecastType];
                  const overallValue =
                    overallValues[selectedPriceArea]?.periods[rowIndex][
                      dataKey
                    ] ?? 0;
                  return (
                    <td key={dataKey} className={classNames(styles.data)}>
                      {overallValue.toFixed(2)}
                    </td>
                  );
                },
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OverallTable;
