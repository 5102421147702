import { fetchProductionPrognose } from "store/appSlice";
import {
  unverifyProductionPrognose,
  updateProductionPrognose,
  verifyProductionPrognose,
} from "api/resources/productionForecasts";
import {
  CellJsonPrognosePeriodProduction,
  ParkData,
  PeriodData,
  ProductionPrognoseValues,
  TableMatrixProduction,
  TotalProduction,
  TotalProductionSum,
} from "api/models/TableMatrix";
import {
  ProductionFieldUpdate,
  ProductionForecast,
  ProductionForecastWeights,
  PrognosePeriodProduction,
  UpdateProductionPrognosisDto,
} from "api/models/ProductionForecast";
import { ProductionPark } from "api/models/ProductionPark";
import { ProtectedPaths } from "routes";
import { ProductionParkType } from "../../../../../../api/models/enums/ProductionParkType";
import React from "react";

export const handleCellClick = (
  tableMatrix: TableMatrixProduction[],
  setTableMatrix: (matrix: TableMatrixProduction[]) => void,
  overallValues: any[],
  setOverallValues: (values: any) => void,
  selectedPriceArea: number,
  parkIndex: number,
  rowIndex: number,
  type: "forecaData" | "aiolosData" | "meteologicaData" | "forecast",
) => {
  const tableMatrixClone = [...tableMatrix];
  const park = tableMatrixClone[selectedPriceArea].parks[parkIndex];
  const reading = park.readings[rowIndex];

  const updateCellSelection = (data: any) => {
    if (data) {
      return {
        ...data,
        selected: !data.selected,
      };
    }
    return data;
  };

  if (type === "forecast") {
    reading.totalProductionSum.forecast.selected =
      !reading.totalProductionSum.forecast.selected;
  } else if (reading.totalProduction[type]) {
    reading.totalProduction[type] = updateCellSelection(
      reading.totalProduction[type],
    );
  }

  let sum = 0;
  let divisor = 0;

  if (reading.totalProduction.forecaData?.selected) {
    sum += reading.totalProduction.forecaData.value;
    divisor++;
  }
  if (reading.totalProduction.aiolosData?.selected) {
    sum += reading.totalProduction.aiolosData.value;
    divisor++;
  }
  if (reading.totalProduction.meteologicaData?.selected) {
    sum += reading.totalProduction.meteologicaData.value;
    divisor++;
  }

  const averageValue = divisor > 0 ? sum / divisor : 0;
  reading.totalProductionSum.forecast.value =
    averageValue + reading.totalProductionSum.adjustment;

  const updatedOverallValues = [...overallValues];
  const overallRow = updatedOverallValues[selectedPriceArea].periods[rowIndex];

  if (type === "forecast") {
    if (!reading.totalProductionSum.forecast.selected) {
      overallRow.forecast -= reading.totalProductionSum.forecast.value;
    } else {
      overallRow.forecast += reading.totalProductionSum.forecast.value;
    }
  } else if (reading.totalProduction[type]) {
    if (!reading.totalProduction[type].selected) {
      overallRow[type] -= reading.totalProduction[type].value;
    } else {
      overallRow[type] += reading.totalProduction[type].value;
    }
  }

  setOverallValues(updatedOverallValues);

  calculateSummaryRow(
    tableMatrixClone,
    setTableMatrix,
    selectedPriceArea,
    rowIndex,
  );

  setTableMatrix(tableMatrixClone);
};

export const handleSaveForecast = (
  tableMatrix: TableMatrixProduction[],
  forecast: ProductionForecast,
  navigate: (path: string) => void,
  availableForecastTypes: string[],
  setSaving: (saving: boolean) => void,
  setSnackbar: (snackbar: { type: string; message: string }) => void,
) => {
  setSaving(true);

  const forecastData: UpdateProductionPrognosisDto = {
    cellsJson: tableMatrix.reduce(
      (acc, priceArea) => {
        priceArea.parks.forEach((park) => {
          if (!acc[park.productionPark.id]) {
            acc[park.productionPark.id] = [];
          }
          park.readings.forEach((reading, index) => {
            acc[park.productionPark.id][index] = {
              datetime: reading.totalProduction.dateTime,
              totalProduction: {
                forecaData: {
                  selected:
                    reading.totalProduction.forecaData?.selected || false,
                },
                aiolosData: {
                  selected:
                    reading.totalProduction.aiolosData?.selected || false,
                },
                meteologicaData: {
                  selected:
                    reading.totalProduction.meteologicaData?.selected || false,
                },
              },
              totalProductionSum: {
                forecast: {
                  selected:
                    reading.totalProductionSum.forecast?.selected || false,
                  value: reading.totalProductionSum.forecast?.value || 0,
                },
                adjustment: reading.totalProductionSum.adjustment,
              },
            } as ProductionFieldUpdate;
          });
        });
        return acc;
      },
      {} as Record<string, ProductionFieldUpdate[]>,
    ),
    productionPrognoseValues: {
      totalValues: tableMatrix.reduce(
        (acc, priceArea) => {
          acc[priceArea.priceArea] = priceArea.periods.map((period, hour) => ({
            hour,
            adjustment: period.adjustment,
            value: period.total,
          }));
          return acc;
        },
        {} as Record<
          string,
          { hour: number; adjustment: number; value: number }[]
        >,
      ),
    },
    filtersJson: tableMatrix.map((priceArea) => ({
      forecastWeights: priceArea.forecastWeights[0],
    })),
  };

  updateProductionPrognose(forecast.id, forecastData)
    .then(() => {
      setSaving(false);
      navigate(`/${ProtectedPaths.Production}`);
    })
    .catch((error) => {
      setSaving(false);
      setSnackbar({
        type: "error",
        message: "Something went wrong when saving the forecast: " + error,
      });
    });
};

export const handleConfirmForecast = (
  forecastId: number,
  setSnackbar: (snackbar: { type: string; message: string }) => void,
  dispatch: any,
) => {
  verifyProductionPrognose(forecastId).then(() => {
    setSnackbar({
      type: "success",
      message: "Forecast confirmed",
    });
    dispatch(fetchProductionPrognose(forecastId));
  });
};

export const handleUnconfirmForecast = (
  forecastId: number,
  setSnackbar: (snackbar: { type: string; message: string }) => void,
  dispatch: any,
) => {
  unverifyProductionPrognose(forecastId).then(() => {
    setSnackbar({
      type: "info",
      message: "Forecast opened for changes",
    });
    dispatch(fetchProductionPrognose(forecastId));
  });
};

export const handleMouseUp = (
  setIsMouseDown: (isMouseDown: boolean) => void,
) => {
  setIsMouseDown(false);
};

export const handleMouseDown = (
  event: any,
  setIsMouseDown: (isMouseDown: boolean) => void,
) => {
  if (event.button === 0) {
    setIsMouseDown(true);
  }
};

export const calculateCapacities = (
  park: ProductionPark,
  forecast: any,
): ProductionPrognoseValues[] => {
  const capacities: ProductionPrognoseValues[] = [];
  const totalProductions = forecast.cellsJson[park.id] || [];
  const forecastWeights = forecast.filtersJson[0].forecastWeights[0];

  totalProductions.slice(0, 25).forEach((production: any) => {
    const forecaDataValue = production.totalProduction.forecaData?.value || 0;
    const aiolosDataValue = production.totalProduction.aiolosData?.value || 0;
    const meteologicaDataValue =
      production.totalProduction.meteologicaData?.value || 0;
    const adjustmentValue = production.totalProductionSum.adjustment || 0;

    const forecaWeight = forecastWeights.forecaWeight || 0;
    const aiolosWeight = forecastWeights.aiolosWeight || 0;
    const meteologicaWeight = forecastWeights.meteologicaWeight || 0;

    const totalWeight = forecaWeight + aiolosWeight + meteologicaWeight;

    const weightedSum =
      forecaDataValue * forecaWeight +
      aiolosDataValue * aiolosWeight +
      meteologicaDataValue * meteologicaWeight;

    const averageValue = totalWeight > 0 ? weightedSum / totalWeight : 0;

    const totalProduction: TotalProduction = {
      dateTime: production.datetime,
      forecaWind: production.totalProduction.forecaWind,
      forecaSymbol: production.totalProduction.forecaSymbol,
      forecaData: production.totalProduction.forecaData,
      aiolosData: production.totalProduction.aiolosData,
      meteologicaData: production.totalProduction.meteologicaData,
    };

    const totalProductionSum: TotalProductionSum = {
      forecast: {
        value: averageValue + adjustmentValue,
        selected: production.totalProductionSum.forecast.selected,
      },
      adjustment: adjustmentValue,
    };

    capacities.push({
      totalProduction,
      totalProductionSum,
    });
  });

  return capacities;
};

export const calculateSummaryRow = (
  tableMatrix: TableMatrixProduction[],
  setTableMatrix: (matrix: TableMatrixProduction[]) => void,
  selectedPriceArea: number,
  rowIndex: number,
) => {
  if (tableMatrix.length === 0) {
    return;
  }

  const tableMatrixClone = [...tableMatrix];

  const calculateSums = (rowIndex: number) => {
    let totalSum = 0;

    tableMatrix[selectedPriceArea].parks.forEach((parkRow: ParkData) => {
      const forecast = parkRow.readings[rowIndex].totalProductionSum.forecast;

      if (forecast.selected) {
        totalSum += forecast.value;
      }
    });

    return totalSum;
  };

  const parkSums = calculateSums(rowIndex);
  const correction =
    tableMatrix[selectedPriceArea].periods[rowIndex].adjustment;

  tableMatrix[selectedPriceArea].periods[rowIndex].total = parkSums;
  tableMatrix[selectedPriceArea].periods[rowIndex].forecast =
    parkSums + correction;

  setTableMatrix(tableMatrixClone);
};

export const calculateTotalValues = (
  tableMatrix: TableMatrixProduction[],
  setTableMatrix: (matrix: TableMatrixProduction[]) => void,
  selectedPriceArea: number,
  availableForecastTypes: string[],
  rowIndex: number,
) => {
  if (tableMatrix.length === 0) {
    return;
  }

  const tableMatrixClone = [...tableMatrix];

  const collectForecastValues = () => {
    const forecastValues: number[] = new Array(25).fill(0);

    tableMatrix[selectedPriceArea].parks.forEach((parkRow: ParkData) => {
      parkRow.readings.forEach((reading, index) => {
        if (reading.totalProductionSum.forecast.selected) {
          forecastValues[index] += reading.totalProductionSum.forecast.value;
        }
      });
    });

    return forecastValues;
  };

  const forecastValues = collectForecastValues();
  const correction =
    tableMatrix[selectedPriceArea].periods[rowIndex].adjustment;

  forecastValues.forEach((value, index) => {
    tableMatrixClone[selectedPriceArea].periods[index].total = value;
    tableMatrixClone[selectedPriceArea].periods[index].forecast =
      value + correction;
  });

  setTableMatrix(tableMatrixClone);
};

export const calculatePeriodData = (
  totalValues: { hour: number; value: number; adjustment: number }[],
): PeriodData[] => {
  return totalValues.slice(0, 25).map((value) => ({
    total: value.value,
    adjustment: value.adjustment,
    forecast: value.value + value.adjustment,
  }));
};

export const handlePriceAreaClick = (
  areaIndex: number,
  setSelectedPriceArea: (areaIndex: number) => void,
) => {
  setSelectedPriceArea(areaIndex);
};

export const handleSetCorrection = (
  value: number,
  rowIndex: number,
  tableMatrix: TableMatrixProduction[],
  setTableMatrix: (matrix: TableMatrixProduction[]) => void,
  selectedPriceArea: number,
  calculateTotal: () => void,
) => {
  const tableMatrixClone = [...tableMatrix];
  const period = tableMatrixClone[selectedPriceArea].periods[rowIndex];

  const previousCorrection = period.adjustment;
  const correctionDifference = value - previousCorrection;

  period.adjustment = value;
  period.forecast += correctionDifference;

  setTableMatrix(tableMatrixClone);
  calculateTotal();
};

export const handleSetParkCorrection = (
  value: number,
  parkIndex: number,
  rowIndex: number,
  tableMatrix: TableMatrixProduction[],
  setTableMatrix: (matrix: TableMatrixProduction[]) => void,
  selectedPriceArea: number,
  calculateTotal: () => void,
) => {
  const tableMatrixClone = [...tableMatrix];
  const park = tableMatrixClone[selectedPriceArea].parks[parkIndex];
  const reading = park.readings[rowIndex];

  const previousAdjustment = reading.totalProductionSum.adjustment;
  const adjustmentDifference = value - previousAdjustment;

  reading.totalProductionSum.adjustment = value;
  reading.totalProductionSum.forecast.value += adjustmentDifference;

  setTableMatrix(tableMatrixClone);
  calculateTotal();
};

export const aggregateForecastData = (
  cellsJson: Record<string, PrognosePeriodProduction[]>,
  priceArea: string,
  productionParks: ProductionPark[],
) => {
  const aggregatedData: Record<number, CellJsonPrognosePeriodProduction> = {};

  const filteredParks = productionParks.filter(
    (park) => park.priceArea === priceArea,
  );

  filteredParks.forEach((park) => {
    const parkId = park.id;
    if (cellsJson[parkId]) {
      cellsJson[parkId].forEach(
        (period: CellJsonPrognosePeriodProduction, index: number) => {
          if (!aggregatedData[index]) {
            aggregatedData[index] = {
              datetime: period.datetime,
              totalProduction: {
                forecaData: { value: 0, selected: true },
                aiolosData: { value: 0, selected: true },
                meteologicaData: { value: 0, selected: true },
              },
              totalProductionSum: {
                forecast: { value: 0, selected: true },
                adjustment: 0,
              },
            };
          }

          if (period.totalProduction.forecaData?.selected) {
            aggregatedData[index].totalProduction.forecaData.value +=
              period.totalProduction.forecaData.value;
          }
          if (period.totalProduction.aiolosData?.selected) {
            aggregatedData[index].totalProduction.aiolosData.value +=
              period.totalProduction.aiolosData.value;
          }
          if (period.totalProduction.meteologicaData?.selected) {
            aggregatedData[index].totalProduction.meteologicaData.value +=
              period.totalProduction.meteologicaData.value;
          }
        },
      );
    }
  });

  return aggregatedData;
};

export const handleWeightChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  weightType: keyof ProductionForecastWeights,
  tableMatrix: TableMatrixProduction[],
  setTableMatrix: (matrix: TableMatrixProduction[]) => void,
  globalForecastWeights: ProductionForecastWeights[],
  forecast: ProductionForecast,
  productionParks: ProductionPark[],
  setWeightChangeTrigger: (value: (prev: number) => number) => void,
) => {
  const tableMatrixClone = [...tableMatrix];

  if (!globalForecastWeights[0]) {
    globalForecastWeights[0] = {} as ProductionForecastWeights;
  }

  const newWeight = parseFloat(e.target.value) || 0;

  const otherWeightTypes: (keyof ProductionForecastWeights)[] =
    forecast.filtersJson[0].type === ProductionParkType.SOLAR
      ? ["forecaWeight", "aiolosWeight"]
      : ["aiolosWeight", "meteologicaWeight"];

  const remainingWeight = 100 - newWeight;

  globalForecastWeights[0][weightType] = newWeight;

  otherWeightTypes
    .filter((type) => type !== weightType)
    .forEach((type) => {
      globalForecastWeights[0][type] = remainingWeight;
    });

  tableMatrixClone.forEach((matrix) => {
    matrix.forecastWeights = globalForecastWeights;
  });

  const updatedCapacities = productionParks.map((park) =>
    calculateCapacities(park, {
      ...forecast,
      filtersJson: [
        {
          ...forecast.filtersJson[0],
          forecastWeights: globalForecastWeights,
        },
      ],
    }),
  );

  tableMatrixClone.forEach((matrix, _) => {
    matrix.parks.forEach((park, parkIndex) => {
      park.readings = updatedCapacities[parkIndex];
    });
  });

  setTableMatrix(tableMatrixClone);
  setWeightChangeTrigger((prev: number) => prev + 1);
};
