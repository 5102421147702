import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Modal } from "@mui/material";
import Table from "components/Table";
import LoadingButton from "@mui/lab/LoadingButton";
import downloadCSV from "helpers/excelExport";
import ConfirmationModal from "components/Modals/Confirmation";
import { ProtectedPaths } from "routes";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "hooks";
import { fetchAllReportsQueries } from "store/appSlice";
import { selectReportsQueries } from "store/selectors";
import {
  deleteReadingsReportQuery,
  getReadingsReportByIdExport,
} from "api/resources/reports";

interface SavedQueriesTableProps {
  setSnackbar: React.Dispatch<React.SetStateAction<any>>;
}

const SavedQueriesTable = ({ setSnackbar }: SavedQueriesTableProps) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(null);

  const dispatch = useAppDispatch();
  const reportsQueries = useAppSelector(selectReportsQueries);

  useEffect(() => {
    dispatch(fetchAllReportsQueries());
  }, []);

  const handleExportReport = (
    queryId: number,
    startDate: string,
    endDate: string,
  ) => {
    // @ts-ignore
    setLoading(queryId);

    const fileName =
      startDate && endDate
        ? dayjs(startDate).format("DD.MM.YYYY") +
          "-" +
          dayjs(endDate).format("DD.MM.YYYY") +
          "_MEASURE"
        : "MEASURE";

    getReadingsReportByIdExport(queryId)
      .then((result) => {
        downloadCSV(result, fileName);
      })
      .then(() => {
        setLoading(false);
        setSnackbar({
          type: "success",
          message: "Readings exported successfully",
        });
      })
      .catch((error) => {
        console.log({ error });
        setLoading(false);
        setSnackbar({
          type: "error",
          message: "Something went wrong",
        });
      });
  };

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
    },
    {
      accessorKey: "period",
      header: "Period",
      cell: (row: any) => {
        return `${dayjs(row.row.original.startDate).format(
          "DD.MM.YYYY",
        )} - ${dayjs(row.row.original.endDate).format("DD.MM.YYYY")}`;
      },
    },
    {
      accessorKey: "actions",
      header: "Actions",
      cell: (row: any) => {
        return (
          <Box>
            <LoadingButton
              onClick={() =>
                handleExportReport(
                  row.row.original.id,
                  row.row.original.startDate,
                  row.row.original.endDate,
                )
              }
              variant="contained"
              loading={loading === row.row.original.id}
              loadingIndicator="Exporting..."
              disabled={loading === row.row.original.id}
              sx={{ mr: "0.5rem" }}
            >
              Run query
            </LoadingButton>
            <Button
              size="small"
              sx={{ mr: "0.5rem" }}
              onClick={() =>
                navigate(
                  `/${ProtectedPaths.EditSavedQuery}/${row.row.original.id}`,
                )
              }
            >
              Edit
            </Button>
            <Button
              size="small"
              onClick={() => setConfirmationModal(row.row.original)}
            >
              Delete
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Table
        data={reportsQueries}
        isFetching={!reportsQueries}
        columns={columns}
      />
      <Modal
        open={confirmationModal}
        onClose={() => setConfirmationModal(null)}
      >
        <ConfirmationModal
          title={`Do you want to delete ${confirmationModal?.name}?`}
          subtitle="This will delete the saved query from the system. This action cannot be undone."
          buttonTitle="Delete"
          action={() => {
            deleteReadingsReportQuery(confirmationModal.id).then(() => {
              dispatch(fetchAllReportsQueries());
              setConfirmationModal(false);
            });
          }}
          setConfirmationModal={setConfirmationModal}
        />
      </Modal>
    </>
  );
};

export default SavedQueriesTable;
