import axios from "axios";

export const parseAxiosError = (error: unknown): string => {
  if (axios.isAxiosError(error) && error.response) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(
      error.response.data,
      "application/xml",
    );
    const message = xmlDoc.getElementsByTagName("message")[0].textContent;
    return message || "An unexpected error occurred";
  }
  throw new Error("An unexpected error occurred");
};
