import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MainLayout from "layouts/Main";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectProductionForecast } from "store/selectors";
import { fetchProductionPrognose } from "store/appSlice";
import EditProductionForecast from "./ProductionForecastData/EditProductionForecast";

const ProductionForecast = () => {
  const [snackbar, setSnackbar] = useState(null);
  const dispatch = useAppDispatch();
  const { forecastId } = useParams();
  const forecast = useAppSelector(selectProductionForecast);

  useEffect(() => {
    if (forecastId) {
      dispatch(fetchProductionPrognose(Number(forecastId)));
    }
  }, [dispatch, forecastId]);

  const snackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(null);
  };

  return (
    <MainLayout
      snackbar={{
        open: snackbar,
        handleClose: snackbarClose,
        type: snackbar?.type,
        message: snackbar?.message,
      }}
    >
      {forecast && forecast.filtersJson[0].productionParks.length > 0 && (
        <EditProductionForecast
          forecast={forecast}
          productionParks={forecast.filtersJson[0].productionParks}
          priceAreas={forecast.filtersJson[0].productionParks.map(
            (park) => park.priceArea,
          )}
          setSnackbar={setSnackbar}
        />
      )}
    </MainLayout>
  );
};

export default ProductionForecast;
