import MainLayout from "layouts/Main";
import { Box, Typography, Button, Stack } from "@mui/material";
import ElevationTable from "./ElevationTable";

const ForecaSettings = () => {
  return (
    <MainLayout
      tabs={[
        { label: "Users", path: "Users" },
        { label: "Operators", path: "Operators" },
        { label: "Litgrid", path: "Litgrid" },
        { label: "Foreca", path: "ForecaSettings" },
        { label: "Elevation coefficents", path: "ElevationCoefficents" },
      ]}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Elevation coefficents</Typography>
        <Stack spacing={2} direction="row">
          <Button variant="outlined">Import</Button>
          <Button variant="contained">Save</Button>
        </Stack>
      </Box>
      <Box display="flex" justifyContent="space-between" sx={{ mt: 4 }}>
        <Box sx={{ maxWidth: "100%" }}>
          <ElevationTable />
        </Box>
      </Box>
    </MainLayout>
  );
};
export default ForecaSettings;
