import React from "react";
import { Box, Button, Chip, Container, FormControl } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

interface CustomDateSectionProps {
  customDate: any;
  setCustomDate: React.Dispatch<React.SetStateAction<any>>;
  formValues: any;
  setFormValues: React.Dispatch<React.SetStateAction<any>>;
}

const CustomDateSection: React.FC<CustomDateSectionProps> = ({
  customDate,
  setCustomDate,
  formValues,
  setFormValues,
}) => {
  const handleAddCustomDate = () => {
    const newFormValues = { ...formValues };
    newFormValues.customDates = [
      ...formValues.customDates,
      dayjs(customDate).format("YYYY-MM-DD"),
    ];
    setFormValues(newFormValues);
    setCustomDate(null);
  };

  return (
    <Container
      maxWidth="md"
      sx={{ background: "#f4f4f4", margin: 0, mt: "1rem", padding: "1rem" }}
    >
      <Box>
        <Box sx={{ mr: "0.5rem" }} display="flex" alignItems="start">
          <FormControl
            sx={{
              minWidth: 280,
              maxWidth: 280,
              background: "white",
            }}
            size="small"
          >
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              name="customDate"
              value={customDate}
              disableFuture={true}
              onChange={(value) => {
                setCustomDate(value);
              }}
              label="Add custom date"
            />
          </FormControl>
          {customDate && (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{ ml: "0.5rem" }}
              onClick={() => handleAddCustomDate()}
            >
              Add
            </Button>
          )}
        </Box>
        <Box sx={{ mt: "0.6rem" }}>
          {formValues.customDates.map((cd: string) => (
            <Chip
              label={dayjs(cd).format("DD.MM.YYYY")}
              size="small"
              sx={{ mr: "0.4rem" }}
              key={"CustomDate-" + cd}
              onDelete={() => {
                const newFormValues = { ...formValues };

                newFormValues.customDates = [
                  ...formValues.customDates.filter((cd1: string) => cd1 !== cd),
                ];

                setFormValues(newFormValues);
              }}
            />
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default CustomDateSection;
