import MainLayout from "layouts/Main";
import ProductionParkTable from "./ProductionParkTable";

const ProductionParks = () => {
  return (
    <MainLayout
      tabs={[
        { label: "Clients", path: "Clients" },
        { label: "Metering points", path: "MeteringPoints" },
        { label: "Contracts", path: "ClientContracts" },
        { label: "Production parks", path: "ProductionParks" },
      ]}
    >
      <ProductionParkTable />
    </MainLayout>
  );
};

export default ProductionParks;
