import { useEffect, useState } from "react";
import dayjs from "dayjs";
import MainLayout from "layouts/Main";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DescriptionIcon from "@mui/icons-material/Description";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAppDispatch, useAppSelector } from "hooks";
import { getProductionForecastExport } from "api/resources/productionForecasts";
import { fetchProductionPrognoses } from "store/appSlice";
import downloadCSV from "helpers/excelExport";
import { ProductionForecast } from "api/models/ProductionForecast";
import { useNavigate } from "react-router-dom";
import ForecastProductionParkTable from "./ForecastProductionParkTable";
import { selectProductionForecasts } from "../../../../../store/selectors";

interface Row {
  date: string;
  forecasts: ProductionForecast[];
}

interface ManageSamplesProps {
  type: "production" | "consumption";
}

const ReadingsForecasts = ({ type }: ManageSamplesProps) => {
  const dispatch = useAppDispatch();
  const forecasts = useAppSelector(selectProductionForecasts);
  const [loading, setLoading] = useState<string | boolean>(false);

  useEffect(() => {
    dispatch(fetchProductionPrognoses());
  }, [dispatch]);

  let rows: Row[] = [];

  forecasts &&
    forecasts.map((forecast) => {
      if (rows.filter((r) => r.date === forecast.prognoseDate).length > 0) {
        const index = rows.findIndex(
          (row) => row.date === forecast.prognoseDate,
        );

        rows[index]?.forecasts.push(forecast);
      } else {
        rows.push({
          date: forecast.prognoseDate,
          forecasts: [forecast],
        });
      }
    });

  rows.reverse();

  console.log({ rows });

  const handleExportForecast = (date: string) => {
    setLoading(date);

    getProductionForecastExport(date).then((result) => {
      console.log({ result });
      downloadCSV(result, `Forecast-${date}`);
      setLoading(false);
    });
  };

  return (
    <MainLayout>
      <Typography variant="h5">
        {type === "production"
          ? "Production Forecasts"
          : "Consumption Forecasts"}
      </Typography>
      <Box sx={{ mt: "1rem" }}>
        {rows &&
          rows.map((row, index) => (
            <Accordion
              defaultExpanded={dayjs(row.date).isTomorrow()}
              sx={{ mb: "1rem", boxShadow: 2 }}
              key={"ForecastSampleAccordion-" + row.date}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  backgroundColor: "#eee",
                }}
              >
                <Typography
                  component="h6"
                  variant="h6"
                  fontSize="1.1rem"
                  fontWeight="400"
                >
                  {`${dayjs(row.date).format("DD.MM.YYYY")} - ${dayjs(
                    row.date,
                  ).format("dddd")}`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box display="flex" alignItems="center" padding="1.6rem 1rem">
                  <Tooltip
                    placement="top"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -8],
                            },
                          },
                        ],
                      },
                    }}
                    title={
                      row.forecasts.filter((fc) => fc.isVerified).length ===
                        0 && "No forecasts confirmed"
                    }
                  >
                    <span>
                      <LoadingButton
                        type="submit"
                        loading={loading === row.date}
                        loadingIndicator="Exporting..."
                        size="small"
                        variant="contained"
                        startIcon={<DescriptionIcon />}
                        onClick={() => handleExportForecast(row.date)}
                        disabled={
                          loading === row.date ||
                          row.forecasts.filter((fc) => fc.isVerified).length ===
                            0
                        }
                      >
                        Export to Excel
                      </LoadingButton>
                    </span>
                  </Tooltip>
                </Box>
                <ForecastProductionParkTable forecasts={row.forecasts} />
              </AccordionDetails>
            </Accordion>
          ))}
      </Box>
    </MainLayout>
  );
};

export default ReadingsForecasts;
