import { createSlice } from "@reduxjs/toolkit";
import { AppProps, Thunk } from "store";
import {
  getClient,
  getClientMeteringPoints,
  getClients,
} from "api/resources/clients";
import { getUsers } from "api/resources/users";
import { getContracts } from "api/resources/contracts";
import { Operator } from "api/models/Operator";
import { User } from "api/models/User";
import { ReportsQuery } from "api/models/Reports";
import { getMeteringPoints } from "api/resources/meteringPoints";
import { getOperators } from "api/resources/operators";
import {
  getAllReportsQueries,
  getReportsQueryOptions,
} from "api/resources/reports";
import { Forecast, ForecastTemplate } from "api/models/Forecast";
import {
  getAllForecasts,
  getAllForecastTemplates,
  getForecast,
} from "api/resources/forecasts";
import {
  getAllProductionParks,
  getProductionParkById,
} from "../api/resources/productionPark";
import {
  getProductionPrognose,
  getProductionPrognoses,
} from "../api/resources/productionForecasts";
import { ForecaCoefficientsData } from "api/models/Foreca";
import { getForecaCoefficents } from "api/resources/foreca";

export const initialState: AppProps = {
  client: null,
  clientMeteringPoints: null,
  clients: null,
  users: [],
  operators: [],
  operator: null,
  meteringPoints: null,
  contracts: null,
  reportsQueryOptions: null,
  reportsQueries: [],
  forecastTemplates: null,
  forecasts: null,
  forecast: null,
  productionPark: null,
  productionParks: null,
  productionParkComponent: null,
  productionParkComponents: null,
  productionForecast: null,
  productionForecasts: [],
  productionForecastSelect: null,
  forecaCoefficients: [],
};

export const appSlice = createSlice({
  name: "app",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    fetchAllClientsReducer(state, data) {
      if (data.payload) {
        state.clients = {
          totalElements: data.payload.totalElements,
          totalPages: data.payload.totalPages,
          size: data.payload.size,
          content: data.payload.content,
        };
      }
    },
    fetchClientReducer(state, data) {
      const client = data.payload;

      if (client) {
        state.client = {
          id: client.id,
          firstName: client.firstName,
          lastName: client.lastName,
          companyName: client.companyName,
          email: client.email,
          phone: client.phone,
          eic: client.eic,
          type: client.type,
          registryCode: client.registryCode,
          personalCode: client.personalCode,
          country: client.country,
          county: client.county,
          city: client.city,
          address: client.address,
          state: client.state,
          zipCode: client.zipCode,
          meteringPointsCount: client.meteringPointsCount,
          iban: client.iban,
          bankName: client.bankName,
        };
      }
    },
    fetchClientMeteringPointsReducer(state, data) {
      if (data.payload) {
        state.clientMeteringPoints = {
          totalElements: data.payload.totalElements,
          totalPages: data.payload.totalPages,
          size: data.payload.size,
          content: data.payload.content,
        };
      }
    },
    fetchAllUsersReducer(state, data) {
      state.users = data.payload.map((user: User) => {
        console.log({ user });
        return {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          permissions: user.permissions,
        };
      });
    },
    fetchAllOperatorsReducer(state, data) {
      state.operators = data.payload.map((operator: Operator) => {
        return {
          id: operator.id,
          eic: operator.eic,
          name: operator.name,
          registryCode: operator.registryCode,
        };
      });
    },
    fetchAllMeteringPointsReducer(state, data) {
      if (data.payload) {
        state.meteringPoints = {
          totalElements: data.payload.totalElements,
          totalPages: data.payload.totalPages,
          size: data.payload.size,
          content: data.payload.content,
        };
      }
    },
    fetchAllContractsReducer(state, data) {
      if (data.payload) {
        state.contracts = {
          totalElements: data.payload.totalElements,
          totalPages: data.payload.totalPages,
          size: data.payload.size,
          content: data.payload.content,
        };
      }
    },
    fetchReportsQueryOptionsReducer(state, data) {
      if (data.payload) {
        state.reportsQueryOptions = {
          periodPresets: data.payload.periodPresets,
          readingTypes: data.payload.readingTypes,
          readingsSumType: data.payload.readingsSumType,
        };
      }
    },
    fetchAllReportsQueriesReducer(state, data) {
      state.reportsQueries = data.payload.map((query: ReportsQuery) => {
        console.log({ query });
        return {
          startDate: query.startDate,
          endDate: query.endDate,
          readingsSumType: query.readingsSumType,
          clients: query.clients,
          meteringPoints: query.meteringPoints,
          contractStartDate: query.contractStartDate,
          contractEndDate: query.contractEndDate,
          name: query.name,
          id: query.id,
        };
      });
    },
    fetchAllForecastTemplatesReducer(state, data) {
      state.forecastTemplates = data.payload.map(
        (template: ForecastTemplate) => {
          return {
            id: template.id,
            name: template.name,
            filters: template.filters,
          };
        },
      );
    },
    fetchAllForecastsReducer(state, data) {
      state.forecasts = data.payload.map((forecast: Forecast) => {
        return {
          id: forecast.id,
          name: forecast.name,
          prognoseDate: forecast.prognoseDate,
          isTemplate: forecast.isTemplate,
          isVerified: forecast.isVerified,
          templateId: forecast.templateId,
          createdByUser: forecast.createdByUser,
          updatedByUser: forecast.updatedByUser,
        };
      });
    },
    fetchForecastReducer(state, data) {
      if (data.payload) {
        console.log(data.payload);
        state.forecast = {
          id: data.payload.id,
          name: data.payload.name,
          prognoseDate: data.payload.prognoseDate,
          meteringPointsCount: data.payload.meteringPointsCount,
          isVerified: data.payload.isVerified,
          customDates: data.payload.customDates,
          filtersJson: data.payload.filtersJson,
          cellsJson: data.payload.cellsJson,
          prognoseValues: data.payload.prognoseValues,
          parentId: data.payload.parentId,
          createdByUser: data.payload.createdByUser,
          updatedByUser: data.payload.updatedByUser,
          isTemplate: data.payload.isTemplate,
          templateId: data.payload.templateId,
        };
      }
    },

    fetchAllProductionParksReducer(state, data) {
      if (data.payload) {
        state.productionParks = {
          totalElements: data.payload.totalElements,
          totalPages: data.payload.totalPages,
          size: data.payload.size,
          content: data.payload.content,
        };
      }
    },

    fetchProductionParkReducer(state, data) {
      if (data.payload) {
        state.productionPark = {
          id: data.payload.id,
          name: data.payload.name,
          code: data.payload.code,
          aiolosCode: data.payload.aiolosCode,
          location: data.payload.location,
          priceArea: data.payload.priceArea,
          type: data.payload.type,
          numberOfInverters: data.payload.numberOfInverters,
          maxPowerOfInverters: data.payload.maxPowerOfInverters,
          numberOfSolarPanels: data.payload.numberOfSolarPanels,
          maxPowerOfSolarPanels: data.payload.maxPowerOfSolarPanels,
          numberOfWindGenerators: data.payload.numberOfWindGenerators,
          maxPowerOfWindGenerators: data.payload.maxPowerOfWindGenerators,
          clientId: data.payload.clientId,
          meteringPoints: data.payload.meteringPoints,
          productionParkComponents: data.payload.productionParkComponents,
        };
      }
    },
    fetchProductionPrognoseReducer(state, action) {
      state.productionForecast = action.payload;
    },
    fetchProductionPrognosesReducer(state, action) {
      state.productionForecasts = action.payload;
    },

    fetchForecaCoefficentsReducer(state, data) {
      state.forecaCoefficients = data.payload.map(
        (forecaDataRow: ForecaCoefficientsData) => {
          return {
            code: forecaDataRow.code,
            symbol: forecaDataRow.symbol,
            coefficient: forecaDataRow.coefficient,
          };
        },
      );
    },
  },
});

export const {
  fetchAllClientsReducer,
  fetchClientReducer,
  fetchAllUsersReducer,
  fetchAllMeteringPointsReducer,
  fetchClientMeteringPointsReducer,
  fetchAllContractsReducer,
  fetchAllOperatorsReducer,
  fetchReportsQueryOptionsReducer,
  fetchAllReportsQueriesReducer,
  fetchAllForecastTemplatesReducer,
  fetchAllForecastsReducer,
  fetchForecastReducer,
  fetchAllProductionParksReducer,
  fetchProductionParkReducer,
  fetchProductionPrognoseReducer,
  fetchProductionPrognosesReducer,
  fetchForecaCoefficentsReducer,
} = appSlice.actions;

export const fetchAllClientsWithFilters =
  (page: number, size: number, filters: { [key: string]: string }): Thunk =>
  (dispatch) => {
    getClients(page, size, filters)
      .then(({ data }) => {
        dispatch(fetchAllClientsReducer(data));
      })
      .catch((err) => console.log(err));
  };

export const fetchAllClients =
  (page: number, size: number): Thunk =>
  (dispatch) => {
    getClients(page, size)
      .then(({ data }) => {
        dispatch(fetchAllClientsReducer(data));
      })
      .catch((err) => console.log(err));
  };

export const fetchClient =
  (clientId: string): Thunk =>
  (dispatch) => {
    dispatch(
      fetchClientReducer({
        id: null,
        firstName: "",
        lastName: "",
        companyName: "",
        email: "",
        phone: "",
        eic: "",
        type: "",
        registryCode: "",
        personalCode: "",
        country: "",
        county: "",
        city: "",
        address: "",
        state: "",
        zipCode: "",
        iban: "",
        bankName: "",
        meteringPointsCount: 0,
      }),
    );

    getClient(clientId)
      .then(({ data }) => {
        dispatch(fetchClientReducer(data));
      })
      .catch((err) => console.log(err));
  };

export const fetchClientMeteringPoints =
  (clientId: string, page: number, size: number): Thunk =>
  (dispatch) => {
    getClientMeteringPoints(clientId, page, size)
      .then(({ data }) => {
        dispatch(fetchClientMeteringPointsReducer(data));
      })
      .catch((err) => console.log(err));
  };

export const fetchAllUsers = (): Thunk => (dispatch) => {
  getUsers()
    .then(({ data }) => {
      dispatch(fetchAllUsersReducer(data));
    })
    .catch((err) => console.log(err));
};

export const fetchAllOperators = (): Thunk => (dispatch) => {
  getOperators()
    .then(({ data }) => {
      dispatch(fetchAllOperatorsReducer(data));
    })
    .catch((err) => console.log(err));
};

export const fetchAllMeteringPoints =
  (page: number, size: number, filters: { [key: string]: string }): Thunk =>
  (dispatch) => {
    getMeteringPoints(page, size, filters)
      .then(({ data }) => {
        dispatch(fetchAllMeteringPointsReducer(data));
      })
      .catch((err) => console.log(err));
  };

export const fetchAllContracts =
  (page: number, size: number, filters: { [key: string]: string }): Thunk =>
  (dispatch) => {
    getContracts(page, size, filters)
      .then(({ data }) => {
        console.log(data);
        dispatch(fetchAllContractsReducer(data));
      })
      .catch((err) => console.log(err));
  };

export const fetchReportsQueryOptions = (): Thunk => (dispatch) => {
  getReportsQueryOptions()
    .then(({ data }) => {
      dispatch(fetchReportsQueryOptionsReducer(data));
    })
    .catch((err) => console.log(err));
};

export const fetchAllReportsQueries = (): Thunk => (dispatch) => {
  getAllReportsQueries()
    .then(({ data }) => {
      dispatch(fetchAllReportsQueriesReducer(data));
    })
    .catch((err) => console.log(err));
};

export const fetchAllForecastTemplates = (): Thunk => (dispatch) => {
  getAllForecastTemplates()
    .then(({ data }) => {
      dispatch(fetchAllForecastTemplatesReducer(data));
    })
    .catch((err) => console.log(err));
};

export const fetchAllForecasts = (): Thunk => (dispatch) => {
  getAllForecasts()
    .then(({ data }) => {
      dispatch(fetchAllForecastsReducer(data));
    })
    .catch((err) => console.log(err));
};

export const fetchForecast =
  (forecastId: string): Thunk =>
  (dispatch) => {
    getForecast(forecastId)
      .then(({ data }) => {
        console.log({ data });
        dispatch(fetchForecastReducer(data));
      })
      .catch((err) => console.log(err));
  };

export const fetchAllProductionPark =
  (page: number, size: number): Thunk =>
  (dispatch) => {
    dispatch(
      fetchAllProductionParksReducer({
        totalElements: 0,
        totalPages: 0,
        size: 0,
        content: [],
      }),
    );

    getAllProductionParks(page, size)
      .then(({ data }) => {
        dispatch(fetchAllProductionParksReducer(data));
      })
      .catch((err) => console.log(err));
  };

export const fetchProductionPark =
  (productionParkId: number): Thunk =>
  (dispatch) => {
    dispatch(
      fetchProductionParkReducer({
        id: null,
        name: "",
        code: "",
        aiolosCode: "",
        location: "",
        priceArea: "",
        type: "",
        numberOfInverters: 0,
        maxPowerOfInverters: 0,
        numberOfSolarPanels: 0,
        maxPowerOfSolarPanels: 0,
        numberOfWindGenerators: 0,
        maxPowerOfWindGenerators: 0,
        clientId: "",
        meteringPoints: [],
        productionParkComponents: [],
      }),
    );

    getProductionParkById(productionParkId)
      .then(({ data }) => {
        dispatch(fetchProductionParkReducer(data));
      })
      .catch((err) => console.log(err));
  };

export const fetchForecaCoefficents = (): Thunk => (dispatch) => {
  getForecaCoefficents()
    .then(({ data }) => {
      console.log({ data });
      dispatch(fetchForecaCoefficentsReducer(data));
    })
    .catch((err) => console.log(err));
};

export const fetchProductionPrognose =
  (id: number): Thunk =>
  async (dispatch) => {
    try {
      dispatch(fetchProductionPrognoseReducer(null));

      const prognose = await getProductionPrognose(id);

      if (prognose.filtersJson.length > 0) {
        const productionParkIds = prognose.filtersJson[0].productionParks;
        const promises = productionParkIds.map((parkId) =>
          getProductionParkById(parkId),
        );
        const results = await Promise.all(promises);

        const productionParks = results.map((result) => result.data);
        const transformedPrognose = {
          ...prognose,
          filtersJson: [
            {
              ...prognose.filtersJson[0],
              productionParks: productionParks,
            },
          ],
        };

        dispatch(fetchProductionPrognoseReducer(transformedPrognose));
      } else {
        dispatch(fetchProductionPrognoseReducer(prognose));
      }
    } catch (error) {
      console.error(error);
    }
  };

export const fetchProductionPrognoses =
  (date?: string): Thunk =>
  async (dispatch) => {
    try {
      const { data } = await getProductionPrognoses(date);
      dispatch(fetchProductionPrognosesReducer(data));
    } catch (error) {
      console.error(error);
    }
  };

export default appSlice.reducer;
