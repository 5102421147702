import * as React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { ProtectedPaths } from "routes";
import { TabModel } from "api/models/TabModel";

interface StyledTabProps {
  label: string;
  onClick: () => void;
  disableRipple?: boolean;
}

interface TabsMenuProps {
  currentPath: string;
  tabs: TabModel[];
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} onClick={props.onClick} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(14),
  marginRight: theme.spacing(2),
  padding: "0",
  color: "black",
  "&.Mui-selected": {
    color: "black",
  },
}));

export default function TabsMenu({ currentPath, tabs }: TabsMenuProps) {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const currentValue = tabs.indexOf(
    tabs.find(
      (t) =>
        ProtectedPaths[t.path as keyof typeof ProtectedPaths] === currentPath,
    ),
  );

  return (
    <Box sx={{ backgroundColor: "#eeeeee", width: "100%" }}>
      <Container maxWidth="xl">
        <Tabs value={currentValue} onChange={handleChange}>
          {tabs.map((tab: any) => (
            <StyledTab
              key={`TabMenuItem-${tab.label}`}
              label={tab.label}
              onClick={() =>
                navigate(
                  "/" + ProtectedPaths[tab.path as keyof typeof ProtectedPaths],
                )
              }
            />
          ))}
        </Tabs>
      </Container>
    </Box>
  );
}
