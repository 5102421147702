import MainLayout from "layouts/Main";
import MeteringPointsTable from "./MeteringPointsTable";

const MeteringPoints = () => {
  return (
    <MainLayout
      tabs={[
        { label: "Clients", path: "Clients" },
        { label: "Metering points", path: "MeteringPoints" },
        { label: "Contracts", path: "ClientContracts" },
        { label: "Production parks", path: "ProductionParks" },
      ]}
    >
      <MeteringPointsTable />
    </MainLayout>
  );
};

export default MeteringPoints;
