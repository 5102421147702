import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

type SelectProps = {
  label: string;
  id: string;
  name: string;
  minWidth?: number;
  disabled?: boolean;
  value?: any;
  defaultValue?: any;
  onChange?: any;
  options: {
    label: string;
    value: any;
  }[];
};

type Option = {
  label: string;
  value: any;
};

const SelectComponent = ({
  label,
  id,
  name,
  disabled,
  value,
  defaultValue,
  onChange,
  minWidth,
  options,
}: SelectProps) => {
  return (
    <FormControl
      sx={{ minWidth: { minWidth }, background: "white" }}
      size="small"
      disabled={disabled}
    >
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        label={label}
        name={name}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        disabled={disabled}
      >
        {options &&
          options.map(({ value, label }: Option) => (
            <MenuItem key={"SelectOption-" + value} value={value}>
              {label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SelectComponent;
