import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { defaultStyle } from "components/Modals";
import { findClient } from "api/resources/clients";
import { ProtectedPaths } from "routes";

interface NewClientModalProps {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewClientModal = ({ setOpenModal }: NewClientModalProps) => {
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState(null);
  const [clientFound, setClientFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const data = new FormData(event.currentTarget);
    const postData = {
      code: data.get("code"),
    };

    findClient(postData)
      .then(({ data }) => {
        setLoading(false);
        const clientId = data.id;
        setOpenModal(false);
        navigate(`/${ProtectedPaths.Clients}/${clientId}`);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setFormErrors(error.response.data.errors);
      });
  };

  return (
    <Box sx={defaultStyle}>
      <Typography id="modal-modal-title" variant="h5" component="h5">
        Add a new client
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2} sx={{ mt: "1rem", mb: "2rem" }}>
          <Grid item sm={8}>
            <TextField
              id="code"
              name="code"
              label="Isikukood / registrikood / EIC"
              size="small"
              onChange={(e) =>
                e.target.value !== "" ? setValid(true) : setValid(false)
              }
              required
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item sm={12} sx={{ mt: "1rem" }}>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
              loadingIndicator="Loading…"
              disabled={loading || !valid}
            >
              Search from AVP
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default NewClientModal;
