import { useState } from "react";
import { Box, Typography, TextField, Button, Grid, Alert } from "@mui/material";
import { defaultStyle } from "components/Modals";
import { updateOperator } from "api/resources/operators";
import { Operator } from "api/models/Operator";
import {FormErrorProps} from "../../../api/models/FormErrorProps";

type EditOperatorModalProps = {
  operator: Operator;
  dispatch: any;
  setEditModal: React.Dispatch<React.SetStateAction<boolean>>;
};


const EditOperatorModal = ({
  operator,
  dispatch,
  setEditModal,
}: EditOperatorModalProps) => {
  const [formErrors, setFormErrors] = useState(null);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const operatorData = {
      eic: data.get("eic"),
      name: data.get("name"),
      registryCode: data.get("registryCode"),
    };

    updateOperator(operator.id, operatorData)
      .then((response) => {
        setEditModal(false);
        dispatch();
      })
      .catch((error) => {
        console.log(error);
        setFormErrors(error.response.data.errors);
      });
  };

  return (
    <Box sx={defaultStyle}>
      <Typography id="modal-modal-title" variant="h5" component="h5">
        Edit user
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2} sx={{ mt: "1rem", mb: "2rem" }}>
          <Grid item sm={12}>
            <TextField
              id="eic"
              name="eic"
              label="EIC"
              size="small"
              required
              defaultValue={operator.eic}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item sm={12}>
            <TextField
              id="name"
              name="name"
              label="Name"
              size="small"
              required
              defaultValue={operator.name}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item sm={12}>
            <TextField
              id="registryCode"
              name="registryCode"
              label="Registry code"
              size="small"
              required
              defaultValue={operator.registryCode}
              sx={{ width: "100%" }}
            />
          </Grid>
          {formErrors && (
            <Grid item sm={12}>
              {formErrors.map(({ message }: FormErrorProps) => (
                <Alert variant="filled" severity="error" sx={{ mb: "0.4rem" }}>
                  {message}
                </Alert>
              ))}
            </Grid>
          )}
          <Grid item sm={12} sx={{ mt: "1rem" }}>
            <Button variant="contained" type="submit" sx={{ mr: "1rem" }}>
              Save
            </Button>
            <Button onClick={() => setEditModal(false)}>Cancel</Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default EditOperatorModal;
