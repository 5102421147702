import axiosClient from "api/axiosInstance";
import {
  ForecaCoefficientsData,
  ForecaCoefficientsDataResponse,
} from "api/models/Foreca";

export const getForecaCoefficents =
  async (): Promise<ForecaCoefficientsDataResponse> => {
    const client = axiosClient();

    const response = await client.get(`/foreca-coefficients`);

    return response.data;
  };

export const updateForecaCoefficents = async (
  data: any,
): Promise<ForecaCoefficientsData> => {
  const client = axiosClient();

  const response = await client.put(`/foreca-coefficients`, data);

  return response.data;
};
