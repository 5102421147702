import MainLayout from "layouts/Main";
import ContractsTable from "./ContractsTable";

const Contracts = () => {
  return (
    <MainLayout
      tabs={[
        { label: "Clients", path: "Clients" },
        { label: "Metering points", path: "MeteringPoints" },
        { label: "Contracts", path: "ClientContracts" },
        { label: "Production parks", path: "ProductionParks" },
      ]}
    >
      <ContractsTable />
    </MainLayout>
  );
};

export default Contracts;
