import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Modal } from "@mui/material";
import Table from "components/Table";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectForecastTemplates } from "store/selectors";
import { fetchAllForecastTemplates } from "store/appSlice";
import { ProtectedPaths } from "routes";
import ConfirmationModal from "components/Modals/Confirmation";
import { deleteForecastTemplate } from "api/resources/forecasts";

const SavedQueriesTable = () => {
  const [confirmationModal, setConfirmationModal] = useState(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const forecastTemplates = useAppSelector(selectForecastTemplates);

  useEffect(() => {
    dispatch(fetchAllForecastTemplates());
  }, []);

  const handleDeleteForecastTemplate = (templateId: number) => {
    deleteForecastTemplate(templateId).then((res) => {
      dispatch(fetchAllForecastTemplates());
    });
  };

  const columns = [
    {
      accessorKey: "name",
      header: "Name",
    },
    {
      accessorKey: "type",
      header: "Type",
    },
    {
      accessorKey: "actions",
      header: "Actions",
      cell: (row: any) => {
        return (
          <Box>
            <Button
              size="small"
              variant="outlined"
              sx={{ mr: "0.5rem" }}
              onClick={() =>
                navigate(
                  `/${
                    ProtectedPaths.EditSavedSample
                  }/${row.row.original.id.toString()}`,
                )
              }
            >
              Edit
            </Button>
            <Button
              size="small"
              onClick={() => setConfirmationModal(row.row.original)}
            >
              Delete
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Table
        data={forecastTemplates}
        isFetching={!forecastTemplates}
        columns={columns}
      />
      {!!confirmationModal && (
        <Modal
          open={confirmationModal}
          onClose={() => setConfirmationModal(null)}
        >
          <ConfirmationModal
            title={`Delete saved sample ${confirmationModal.name}?`}
            subtitle="Are you sure you want to delete the saved sample? This action cannot be undone."
            buttonTitle="Delete"
            action={() => {
              handleDeleteForecastTemplate(confirmationModal.id);
              setConfirmationModal(null);
            }}
            setConfirmationModal={setConfirmationModal}
          />
        </Modal>
      )}
    </>
  );
};

export default SavedQueriesTable;
