import React, { useEffect, useState } from "react";
import { Box, Checkbox, Chip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "components/TextField";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { getMeteringPoints } from "api/resources/meteringPoints";
import {
  ReportsFormValues,
  SelectedClients,
} from "../../../../api/models/ReportsFormValues";
import ContractDatesSelect from "./ContractDatesSelect";
import { searchComponent } from "../../../../components/helpers/componentUtil";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface MeteringPointsSelectProps {
  formValues: ReportsFormValues;
  setFormValues: React.Dispatch<React.SetStateAction<ReportsFormValues>>;
}

const MeteringPointsSelect = ({
  formValues,
  setFormValues,
}: MeteringPointsSelectProps) => {
  const [searchStringMeteringPoint, setSearchStringMeteringPoint] =
    useState("");
  const [meteringPointsOptions, setMeteringPointsOptions] = useState([]);

  const handleSelectMeteringPointsOption = (option: any) => {
    let selectedMeteringPointsOptionsData = [
      ...formValues.selectedMeteringPointsOptions,
    ];

    if (
      !!selectedMeteringPointsOptionsData.find(
        (sc) => sc.value === option.value,
      )
    ) {
      selectedMeteringPointsOptionsData =
        selectedMeteringPointsOptionsData.filter(
          (sc) => sc.value !== option.value,
        );
    } else {
      selectedMeteringPointsOptionsData = [
        ...selectedMeteringPointsOptionsData,
        option,
      ];
    }

    const newFormValues = { ...formValues };
    newFormValues.selectedMeteringPointsOptions =
      selectedMeteringPointsOptionsData;
    setFormValues(newFormValues);
  };

  const handleSearchMeteringPoint = () => {
    getMeteringPoints(0, 25, null, searchStringMeteringPoint).then((result) => {
      const newMeteringPointsOptions = result?.data.content.map((c) => ({
        name: c.eic,
        id: c.id,
        address: c.address + ", " + c.city,
      }));
      setMeteringPointsOptions(newMeteringPointsOptions);
    });
  };

  useEffect(() => {
    searchComponent(searchStringMeteringPoint, handleSearchMeteringPoint);
  }, [searchStringMeteringPoint, searchComponent]);

  const handleRemoveSelectedMeteringPoint = (option: any) => {
    let selectedMeteringPointsData = [
      ...formValues.selectedMeteringPointsOptions,
    ];

    selectedMeteringPointsData = selectedMeteringPointsData.filter(
      (sc) => sc.title !== option.title,
    );

    const newFormValues = { ...formValues };
    newFormValues.selectedMeteringPointsOptions = selectedMeteringPointsData;
    setFormValues(newFormValues);
  };

  return (
    <Box display="flex" alignItems="start" mb="0.5rem">
      <Box mr="0.5rem">
        <Autocomplete
          multiple
          id="selectedMeteringPoints"
          value={formValues.selectedMeteringPointsOptions}
          limitTags={2}
          disableCloseOnSelect
          options={
            meteringPointsOptions
              ? meteringPointsOptions.map((meteringPoint) => {
                  return {
                    title: meteringPoint.name,
                    value: meteringPoint.id,
                    address: meteringPoint.address,
                  };
                })
              : []
          }
          getOptionLabel={(option) => option.title}
          defaultValue={[]}
          size="small"
          renderTags={(value: SelectedClients[], getTagProps) =>
            value?.map((option: any, index: number) => {
              const { key } = getTagProps({ index });
              return (
                <Chip
                  label={option.title}
                  key={key}
                  onDelete={() => handleRemoveSelectedMeteringPoint(option)}
                  size="small"
                  sx={{ mr: "4px", mb: "4px" }}
                />
              );
            })
          }
          renderInput={(params) => (
            <TextField
              id="client"
              params={params}
              minWidth={480}
              maxWidth={480}
              label="Metering points"
              placeholder="Search by metering point EIC"
              onChange={(e: any) =>
                setSearchStringMeteringPoint(e.target.value)
              }
            />
          )}
          renderOption={(props, option) => {
            const isSelected = !!formValues.selectedMeteringPointsOptions.find(
              (sc) => sc.value === option.value,
            );

            return (
              <li
                {...props}
                style={{ padding: "0rem" }}
                onClick={() => handleSelectMeteringPointsOption(option)}
              >
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 4 }}
                  checked={isSelected}
                />
                {`${option.title} - ${option.address}`}
              </li>
            );
          }}
        />
      </Box>
      <ContractDatesSelect
        formValues={formValues}
        setFormValues={setFormValues}
      />
    </Box>
  );
};

export default MeteringPointsSelect;
