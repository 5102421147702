import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { countries } from "./countries";
import { CountryType } from "../../api/models/CountryType";
import React from "react";

interface CountrySelectProps {
  value: CountryType | null;
  setValue: (country: CountryType | null) => void;
  label: string;
}

export default function CountrySelect({
  value,
  setValue,
  label,
}: CountrySelectProps) {
  return (
    <Autocomplete
      id="country-select"
      options={countries}
      value={
        value && typeof value === "string"
          ? countries.find((c) => c.label === value)
          : value
      }
      onChange={(event, newValue) => setValue(newValue)}
      size="small"
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
