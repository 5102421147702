import { useState } from "react";
import { Box, Typography, TextField, Button, Grid, Alert } from "@mui/material";
import { defaultStyle } from "components/Modals";
import { createUser } from "api/resources/users";
import { Permission } from "api/models/Permission";
import Permissions from "./Permissions";
import {FormErrorProps} from "../../../api/models/FormErrorProps";

interface NewUserModalProps {
  dispatch: any;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewUserModal = ({ dispatch, setOpenModal }: NewUserModalProps) => {
  const [formErrors, setFormErrors] = useState(null);
  const [selectedPermissions, setSelectedPermissions] = useState<Permission[]>(
    []
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const newPermissions: Array<number> = [];
    selectedPermissions.map((p) => {
      newPermissions.push(p.id);
    });

    const userData = {
      email: data.get("email"),
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      password: data.get("password"),
      passwordConfirmed: data.get("passwordConfirmed"),
      permissions: newPermissions,
    };

    createUser(userData)
      .then((response) => {
        setOpenModal(false);
        dispatch();
      })
      .catch((error) => {
        console.log(error);
        setFormErrors(error.response.data.errors);
      });
  };

  return (
    <Box sx={defaultStyle}>
      <Typography id="modal-modal-title" variant="h5" component="h5">
        Add a new user
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2} sx={{ mt: "1rem", mb: "2rem" }}>
          <Grid item sm={6}>
            <TextField
              id="firstName"
              name="firstName"
              label="First name"
              size="small"
              required
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              id="lastName"
              name="lastName"
              label="Last name"
              size="small"
              required
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item sm={12}>
            <TextField
              id="email"
              name="email"
              label="Email"
              size="small"
              required
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              type="password"
              id="password"
              name="password"
              label="Password"
              size="small"
              required
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              type="password"
              id="passwordConfirmed"
              name="passwordConfirmed"
              label="Repeat password"
              size="small"
              required
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item sm={12} sx={{ mt: "1rem" }}>
            <Typography id="modal-modal-title" component="h5">
              Permissions
            </Typography>
            <Grid item sm={12}>
              <Permissions
                selectedPermissions={selectedPermissions}
                setSelectedPermissions={setSelectedPermissions}
              />
            </Grid>
          </Grid>
          {formErrors && (
            <Grid item sm={12}>
              {formErrors.map(({ message }: FormErrorProps) => (
                <Alert variant="filled" severity="error" sx={{ mb: "0.4rem" }}>
                  {message}
                </Alert>
              ))}
            </Grid>
          )}
          <Grid item sm={12} sx={{ mt: "1rem" }}>
            <Button variant="contained" type="submit">
              Add user
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default NewUserModal;
