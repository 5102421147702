import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import CountrySelect from "../../../../../components/CountrySelect";
import ProductionParkMeteringPoints from "../Components/ProductionParkMeteringPoints";
import ComponentForm from "./ComponentForm";
import { CountryType } from "../../../../../api/models/CountryType";
import { ProductionPark } from "../../../../../api/models/ProductionPark";
import ConfirmationModal from "../../../../../components/Modals/Confirmation";
import ClientSelect from "../../../../../components/ClientSelect/ClientSelect";
import { SelectChangeEvent } from "@mui/material/Select";

interface ProductionParkFormProps {
  formData: ProductionPark;
  setFormData: React.Dispatch<React.SetStateAction<ProductionPark>>;
  selectedCountry: CountryType;
  handleCountrySelect: (country: CountryType) => void;
  selectedClients: { title: string; value: number }[];
  setSelectedClients: React.Dispatch<
    React.SetStateAction<{ title: string; value: number }[]>
  >;
  handleChange: (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
    index?: number,
  ) => void;
  handleRemoveComponent: (index: number) => void;
  handleAddComponent: () => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  deleteModalOpen?: boolean;
  openDeleteModal?: () => void;
  closeDeleteModal?: () => void;
  handleDelete?: () => void;
  showDeleteButton: boolean; // New prop
  productionParkId?: number;
}

const ProductionParkForm: React.FC<ProductionParkFormProps> = ({
  formData,
  setFormData,
  selectedCountry,
  handleCountrySelect,
  selectedClients,
  setSelectedClients,
  handleChange,
  handleRemoveComponent,
  handleAddComponent,
  handleSubmit,
  deleteModalOpen,
  openDeleteModal,
  closeDeleteModal,
  handleDelete,
  showDeleteButton, // New prop
  productionParkId,
}) => {
  const [isFormValid, setIsFormValid] = useState(false);

  const checkFormValidity = () => {
    const { name, location } = formData;
    return name.trim() !== "" && location.trim() !== "";
  };

  useEffect(() => {
    setIsFormValid(checkFormValidity());
  }, [formData]);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid item xs={12} sx={{ paddingTop: 1, paddingBottom: 1 }}>
            <TextField
              fullWidth
              label="Name"
              name="name"
              size="small"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              required={true}
            />
          </Grid>
          <Grid item sx={{ paddingTop: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={6} sx={{ paddingTop: 1, paddingBottom: 1 }}>
                <TextField
                  fullWidth
                  label="Meteologica code"
                  name="code"
                  size="small"
                  value={formData.code}
                  onChange={(e) =>
                    setFormData({ ...formData, code: e.target.value })
                  }
                  required={true}
                />
              </Grid>
              <Grid item xs={6} sx={{ paddingTop: 1, paddingBottom: 1 }}>
                <TextField
                  fullWidth
                  label="Aiolos code"
                  name="aiolosCode"
                  size="small"
                  value={formData.aiolosCode || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, aiolosCode: e.target.value })
                  }
                  required={true}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: 1, paddingBottom: 1 }}>
            <TextField
              fullWidth
              label="Location"
              name="location"
              size="small"
              value={formData.location}
              onChange={(e) =>
                setFormData({ ...formData, location: e.target.value })
              }
              required={true}
            />
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: 1, paddingBottom: 1 }}>
            <CountrySelect
              value={selectedCountry}
              setValue={handleCountrySelect}
              label="Price area"
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={12} sx={{ paddingTop: 1, paddingBottom: 1 }}>
            <ClientSelect
              selectedClients={selectedClients}
              setSelectedClients={setSelectedClients}
            />
          </Grid>
          {selectedClients[0]?.value && (
            <Grid item xs={12} sx={{ paddingTop: 1, paddingBottom: 1 }}>
              <ProductionParkMeteringPoints
                formValues={formData}
                setFormValues={setFormData}
                clientId={selectedClients[0]?.value || 0}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Components</Typography>
          {formData.productionParkComponents.map((component, index) => {
            const componentWithDefaults = {
              ...component,
              maintenances: component.maintenances || [],
              limits: component.limits || [],
            };

            return (
              <ComponentForm
                key={index}
                handleChange={handleChange}
                data={componentWithDefaults}
                handleRemove={handleRemoveComponent}
                productionParkId={productionParkId}
                index={index}
              />
            );
          })}
          <Box paddingTop={3}>
            <Button variant="contained" onClick={handleAddComponent}>
              Add Component
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item>
          <Button
            disabled={!isFormValid}
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </Grid>
        {showDeleteButton && (
          <>
            <Grid item xs={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={openDeleteModal}
              >
                Delete park
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Modal open={deleteModalOpen} onClose={() => closeDeleteModal}>
                <ConfirmationModal
                  action={handleDelete}
                  title={`Do you want to delete the park ${formData.name}?`}
                  subtitle={
                    "This will remove the park from the system, but not past forecasts and measurement data"
                  }
                  buttonTitle={"Delete park"}
                  setConfirmationModal={closeDeleteModal}
                />
              </Modal>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default ProductionParkForm;
