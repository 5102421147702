import React, { useState } from "react";
import dayjs from "dayjs";
import { Box, FormControl, Grid, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { grey } from "@mui/material/colors";
import { defaultStyle } from "components/Modals";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { updateProductionParkDetails } from "api/resources/productionPark";
import { fetchProductionPark } from "store/appSlice";
import { useAppDispatch } from "hooks";
import { ProductionParkComponentType } from "api/models/enums/ProductionParkComponentType";
import { ProductionParkComponent } from "api/models/ProductionParkComponent";

interface ServiceModalProps {
  productionParkId: number;
  componentId: number;
  componentType: ProductionParkComponentType;
  maxCount: number;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ServiceModal = ({
  productionParkId,
  componentId,
  componentType,
  maxCount,
  setOpenModal,
}: ServiceModalProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    from: null,
    to: null,
    quantity: 1,
  });

  const handleSubmit = () => {
    setLoading(true);
    const postData: Partial<ProductionParkComponent> = {
      type: componentType as ProductionParkComponentType,
      maintenances: [
        {
          maintenanceFromDate: formData.from,
          maintenanceToDate: formData.to,
          maintenanceCount: formData.quantity,
        },
      ],
    };

    updateProductionParkDetails(componentId, postData).then(() => {
      dispatch(fetchProductionPark(Number(productionParkId)));
      setLoading(false);
      setOpenModal(false);
    });
  };

  return (
    <Box sx={defaultStyle}>
      <Typography id="modal-modal-title" variant="h5" component="h5">
        Add service
      </Typography>
      <Typography
        id="modal-modal-title"
        component="p"
        sx={{ color: grey[700], mt: "0.6rem" }}
      >
        Set a service period for the component. This will remove the production
        capacity from the forecast generation.
      </Typography>
      <Grid container spacing={0} sx={{ mb: "2rem" }}>
        <Grid item sm={8}>
          <Grid container sx={{ mt: "2rem", mb: "1rem" }}>
            <Grid item md={6}>
              <DatePicker
                label={"From"}
                name="from"
                slotProps={{
                  textField: { size: "small" },
                  clearButton: { size: "small" },
                }}
                minDate={dayjs()}
                value={formData.from}
                onChange={(value) => {
                  const formDataCopy = { ...formData };

                  formDataCopy.from = dayjs(value).format("YYYY-MM-DD");

                  setFormData(formDataCopy);
                }}
                sx={{ mr: "0.8rem" }}
              />
            </Grid>
            <Grid item md={6}>
              <DatePicker
                label={"To"}
                name="to"
                sx={{ background: "white" }}
                slotProps={{ textField: { size: "small" } }}
                minDate={dayjs(formData.from)}
                value={formData.to}
                onChange={(value) => {
                  const formDataCopy = { ...formData };

                  formDataCopy.to = dayjs(value).format("YYYY-MM-DD");

                  setFormData(formDataCopy);
                }}
              />
            </Grid>
          </Grid>
          <FormControl fullWidth>
            <TextField
              id="quantity"
              name="quantity"
              label="Quantity"
              type="number"
              size="small"
              InputProps={{ inputProps: { min: 1, max: maxCount } }}
              sx={{ maxWidth: "100px" }}
              value={formData.quantity}
              onChange={(e) => {
                const formDataCopy = { ...formData };

                formDataCopy.quantity = Number(e.target.value);

                setFormData(formDataCopy);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item sm={12} sx={{ mt: "2rem" }}>
          <LoadingButton
            variant="contained"
            loading={loading}
            loadingIndicator="Saving..."
            onClick={() => handleSubmit()}
            disabled={loading || !formData.from}
          >
            Save service
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServiceModal;
