import { useState, useEffect } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { getPermissions } from "api/resources/users";
import { PermissionTypes } from "helpers/enums";
import { Permission } from "api/models/Permission";
import { PermissionsProps } from "../../../api/models/PermissionProps";

function getStyles(
  name: string,
  selectedPermissions: readonly any[],
  theme: Theme,
) {
  return {
    fontWeight: selectedPermissions.find((ap) => ap.name === name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

export default function Permissions({
  selectedPermissions,
  setSelectedPermissions,
}: PermissionsProps) {
  const theme = useTheme();
  const [allPermissions, setAllPermissions] = useState([]);

  useEffect(() => {
    getPermissions()
      .then((response) => {
        setAllPermissions(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSelectPermission = (value: Permission) => {
    if (!selectedPermissions.some((sp) => sp.id === value.id)) {
      setSelectedPermissions([...selectedPermissions, value]);
    } else {
      setSelectedPermissions([
        ...selectedPermissions.filter((sp) => sp.id !== value.id),
      ]);
    }
  };

  return (
    <div>
      <FormControl sx={{ mt: "1rem", width: "100%" }}>
        <InputLabel id="choosePermissions" size="small">
          Choose permissions
        </InputLabel>
        <Select
          size="small"
          multiple
          value={selectedPermissions}
          input={
            <OutlinedInput
              id="inputChoosePermissions"
              label="Choose permissions"
            />
          }
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value.id}
                  label={
                    PermissionTypes[value.name as keyof typeof PermissionTypes]
                  }
                />
              ))}
            </Box>
          )}
        >
          {allPermissions.map((permission, index) => (
            <MenuItem
              key={"PermissionMenuItem-" + permission.id}
              value={permission}
              onClick={() => handleSelectPermission(permission)}
              style={getStyles(permission.name, selectedPermissions, theme)}
            >
              {PermissionTypes[permission.name as keyof typeof PermissionTypes]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
