import React from "react";
import { Box, Button, Container, Stack } from "@mui/material";
import Select from "components/Select";
import TextField from "components/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAppSelector } from "hooks";
import { selectOperators } from "store/selectors";
import { ReportsFormValues } from "../../../api/models/ReportsFormValues";
import DateBox from "./Components/DateBox";
import MeteringPointsSelect from "./Components/MeteringPointsSelect";
import ClientSelect from "../../../components/ClientSelect/ClientSelect";

interface ReportFiltersProps {
  onSubmit: any;
  formValues: ReportsFormValues;
  setFormValues: React.Dispatch<React.SetStateAction<ReportsFormValues>>;
  loading: boolean;
  submitBtnTitle: string;
  secondBtnAction?: any;
  secondBtnTitle?: string;
  editName: boolean;
  thirdBtnTitle?: string;
  thirdBtnAction?: any;
  thirdBtnLoading?: boolean;
}

const ReportFilters = ({
  onSubmit,
  formValues,
  setFormValues,
  loading,
  submitBtnTitle,
  secondBtnAction,
  secondBtnTitle,
  editName,
  thirdBtnTitle,
  thirdBtnAction,
  thirdBtnLoading,
}: ReportFiltersProps) => {
  const operators = useAppSelector(selectOperators);

  const setSelectedClients = (
    selectedClients: { title: string; value: number }[],
  ) => {
    setFormValues({ ...formValues, selectedClients });
  };

  return (
    <Box component="form" onSubmit={onSubmit} noValidate>
      {editName && (
        <Container
          maxWidth={false}
          sx={{
            background: "#f4f4f4",
            margin: 0,
            mt: "1rem",
            padding: "1rem",
            maxWidth: "940px",
          }}
        >
          <TextField
            id="tarbimiskohad"
            value={formValues.name}
            onChange={(e: any) => {
              console.log({ e });
              const newFormValues = { ...formValues };

              newFormValues.name = e.target.value;

              setFormValues(newFormValues);
            }}
            label="Saved query title"
            minWidth={360}
          />
        </Container>
      )}
      <DateBox formValues={formValues} setFormValues={setFormValues} />
      <Container
        maxWidth={false}
        sx={{
          background: "#f4f4f4",
          margin: 0,
          mt: "1rem",
          padding: "1rem",
          maxWidth: "940px",
        }}
      >
        <Box display="flex" alignItems="start" mb="0.5rem">
          <ClientSelect
            selectedClients={formValues.selectedClients}
            setSelectedClients={setSelectedClients}
          />
          <Box>
            <Select
              label="Seller"
              id="selectedOperator"
              name="selectedOperator"
              value={formValues.selectedOperator}
              disabled
              minWidth={400}
              options={
                operators
                  ? operators.map((operator: any) => {
                      return {
                        label: operator.name,
                        value: operator.id,
                      };
                    })
                  : []
              }
            />
          </Box>
        </Box>
        <MeteringPointsSelect
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </Container>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ maxWidth: "940px", marginTop: "2rem" }}
      >
        <Stack spacing={2} direction="row">
          <LoadingButton
            type="submit"
            variant="contained"
            loading={loading}
            loadingIndicator="Loading..."
            disabled={loading}
          >
            {submitBtnTitle}
          </LoadingButton>
          <Button variant="text" onClick={secondBtnAction}>
            {secondBtnTitle}
          </Button>
        </Stack>
        {thirdBtnTitle && thirdBtnAction && (
          <LoadingButton
            variant="outlined"
            onClick={() => thirdBtnAction()}
            loading={thirdBtnLoading}
            loadingIndicator="Loading..."
            disabled={thirdBtnLoading}
          >
            Run query
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
};

export default ReportFilters;
