import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Checkbox, Chip } from "@mui/material";
import TextField from "../../../../components/TextField";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { getClients } from "../../../../api/resources/clients";
import { searchComponent } from "../../../../components/helpers/componentUtil";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface ClientSelectionProps {
  selectedClients: any[];
  setSelectedClients: React.Dispatch<React.SetStateAction<any[]>>;
  formValues: any;
  setFormValues: React.Dispatch<React.SetStateAction<any>>;
}

const ClientSelection: React.FC<ClientSelectionProps> = ({
  selectedClients,
  setSelectedClients,
  formValues,
  setFormValues,
}) => {
  const [clientOptions, setClientOptions] = useState([]);
  const [searchClientString, setSearchClientString] = useState("");

  useEffect(() => {
    searchComponent(searchClientString, handleSearchClient);
  }, [searchClientString, searchComponent]);

  const handleSearchClient = () => {
    getClients(0, 15, null, searchClientString).then((result) => {
      const clientsOptions = result?.data.content.map((client) => {
        const name =
          client.type === "BUSINESS"
            ? client.companyName
            : client.firstName + " " + client.lastName;
        return { title: name, value: client.id };
      });
      setClientOptions(clientsOptions);
    });
  };

  const handleSelectClient = (option: any) => {
    let selectedClientsData = [...selectedClients];
    if (
      selectedClientsData.find(
        (sc) => typeof sc === "object" && sc.value === option.value,
      )
    ) {
      selectedClientsData = selectedClientsData.filter(
        (sc) => typeof sc === "object" && sc.value !== option.value,
      );
    } else {
      selectedClientsData = [...selectedClientsData, option];
    }
    setSelectedClients(selectedClientsData);
    const newFormValues = { ...formValues };
    newFormValues.selectedClients = selectedClientsData.map((scd) =>
      typeof scd === "object" ? scd.value : scd,
    );
    setFormValues(newFormValues);
  };

  const handleRemoveClient = (id: number) => {
    const newSelectedClients = selectedClients.filter(
      (client) => typeof client === "object" && client.value !== id,
    );
    setSelectedClients(newSelectedClients);
    const newFormValues = { ...formValues };
    newFormValues.selectedClients = newSelectedClients.map((client) =>
      typeof client === "object" ? client.value : client,
    );
    setFormValues(newFormValues);
  };

  return (
    <Box sx={{ mr: "0.5rem" }}>
      <Autocomplete
        multiple
        id="selectedClients"
        onChange={(event, value) => {
          setSelectedClients(value);
          setFormValues((prevValues: any) => ({
            ...prevValues,
            selectedClients: value,
          }));
        }}
        value={selectedClients}
        limitTags={2}
        disableCloseOnSelect
        options={clientOptions}
        getOptionLabel={(option) => option.title}
        defaultValue={[]}
        size="small"
        renderInput={(params) => (
          <TextField
            id="client"
            params={params}
            minWidth={420}
            maxWidth={420}
            label="Client"
            onChange={(e: any) => setSearchClientString(e.target.value)}
          />
        )}
        renderTags={(value: readonly string[], getTagProps) =>
          value?.map((option: any, index: number) => {
            const { key } = getTagProps({ index });
            return (
              <Chip
                label={option.title}
                key={key}
                onDelete={() => handleRemoveClient(option.value)}
                size="small"
                sx={{ mr: "4px", mb: "4px" }}
              />
            );
          })
        }
        renderOption={(props, option) => {
          const isSelected = !!selectedClients.find(
            (sc) => typeof sc === "object" && sc.value === option.value,
          );

          return (
            <li
              {...props}
              style={{ padding: "0rem" }}
              onClick={() => handleSelectClient(option)}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 4 }}
                checked={isSelected}
              />
              {option.title}
            </li>
          );
        }}
      />
    </Box>
  );
};

export default ClientSelection;
