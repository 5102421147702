import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "provider/authProvider";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import PersonIcon from "@mui/icons-material/Person";
import Fade from "@mui/material/Fade";
import { ProtectedPaths } from "routes";
import { indigo } from "@mui/material/colors";
// @ts-ignore
import Logo from "assets/images/logo.svg?react";
import TabsMenu from "./TabsMenu";
import { TabModel } from "../../api/models/TabModel";

interface HeaderProps {
  tabs: TabModel[];
}

export default function Header({ tabs }: HeaderProps) {
  const { setToken } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname.replace("/", "");

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorElForecasts, setAnchorElForecasts] =
    React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenForecastsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElForecasts(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseForecastsMenu = () => {
    setAnchorElForecasts(null);
  };

  const handleLogout = () => {
    setToken("");
    navigate("/login", { replace: true });
  };

  const menuItems = [
    {
      label: "Reports",
      path: ProtectedPaths.Readings,
    },
    {
      label: "Forecasts",
      submenu: [
        {
          label: "Production",
          path: ProtectedPaths.Production,
        },
        {
          label: "Consumption",
          path: ProtectedPaths.Consumption,
        },
      ],
    },
    {
      label: "Registers",
      path: ProtectedPaths.Clients,
    },
    {
      label: "Settings",
      path: ProtectedPaths.Users,
    },
  ];

  return (
    <>
      <AppBar position="static" sx={{ bgcolor: indigo[900] }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ mr: 2 }}>
              <Logo />
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                TransitionComponent={Fade}
                sx={{
                  display: { xs: "flex", md: "none" },
                }}
              >
                {menuItems.map((page) => [
                  <MenuItem
                    key={page.label}
                    onClick={
                      page.submenu
                        ? handleOpenForecastsMenu
                        : () => navigate(`/${page.path}`)
                    }
                    selected={currentPath === page.path && true}
                  >
                    <Typography textAlign="center">{page.label}</Typography>
                  </MenuItem>,
                  page.submenu && (
                    <Menu
                      key={`${page.label}-submenu`}
                      id="submenu-appbar"
                      anchorEl={anchorElForecasts}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      keepMounted
                      open={Boolean(anchorElForecasts)}
                      onClose={handleCloseForecastsMenu}
                      TransitionComponent={Fade}
                    >
                      {page.submenu.map((subPage) => (
                        <MenuItem
                          key={subPage.label}
                          onClick={() => {
                            navigate(`/${subPage.path}`);
                            handleCloseForecastsMenu();
                          }}
                          selected={currentPath === subPage.path && true}
                        >
                          <Typography textAlign="center">
                            {subPage.label}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  ),
                ])}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {menuItems.map((page) => [
                <MenuItem
                  key={page.label}
                  onClick={
                    page.submenu
                      ? handleOpenForecastsMenu
                      : () => navigate(`/${page.path}`)
                  }
                  selected={currentPath === page.path && true}
                >
                  <Typography textAlign="center">{page.label}</Typography>
                </MenuItem>,
              ])}
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar sx={{ bgcolor: indigo[500] }} alt="Tanel Nigul">
                  <PersonIcon />
                </Avatar>
              </IconButton>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                TransitionComponent={Fade}
              >
                <MenuItem key={"Log out"} onClick={handleLogout}>
                  <Typography textAlign="center">Log out</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {tabs && <TabsMenu currentPath={currentPath} tabs={tabs} />}
    </>
  );
}
