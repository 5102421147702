import axiosClient from "api/axiosInstance";
import { MeteringPoints } from "api/models/MeteringPoints";
import { GridRowId } from "@mui/x-data-grid";

export const getMeteringPoints = async (
  page: number,
  size: number,
  filters: { [key: string]: string },
  query?: string,
): Promise<MeteringPoints> => {
  const client = axiosClient();

  const queryParams = new URLSearchParams({
    page: page.toString(),
    size: size.toString(),
    ...filters,
  }).toString();

  const queryParam = query ? `&query=${query}` : "";

  const response = await client.get(
    `/metering-points?${queryParams}${queryParam}`,
  );

  return response.data;
};

export const getSingleMeteringPoint = async (
  id: number,
): Promise<MeteringPoints> => {
  const client = axiosClient();

  const response = await client.get(`/metering-points/${id}`);

  return response.data;
};

export const fetchMeteringPointReadings = async (data: {
  meteringPointIds: GridRowId[];
}): Promise<MeteringPoints> => {
  const client = axiosClient();

  const response = await client.put(`/metering-points/fetch-readings`, data);

  return response.data;
};
