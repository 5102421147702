import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "layouts/Main";
import { Box, Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ProtectedPaths } from "routes";
import SavedSamplesTable from "./SavedSamplesTable";

const Reports = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen(!open);

  return (
    <MainLayout
      tabs={[
        { label: "Manage samples", path: "Consumption" },
        { label: "Saved samples", path: "SavedSamples" },
      ]}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Saved samples</Typography>
        <Button
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
          onClick={() => navigate(`/${ProtectedPaths.EditSavedSample}`)}
        >
          Add sample
        </Button>
      </Box>
      <SavedSamplesTable />
    </MainLayout>
  );
};

export default Reports;
