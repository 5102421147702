import React from "react";
import { Box, Container, FormControl } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { ReportsFormValues } from "../../../../api/models/ReportsFormValues";
import Select from "../../../../components/Select";
import {
  PeriodPresetTypes,
  ReadingSumTypeTypes,
  ReadingTypeTypes,
} from "../../../../helpers/enums";
import { useAppSelector } from "../../../../hooks";
import { selectReportsQueryOptions } from "../../../../store/selectors";

interface DateRangePickerProps {
  formValues: ReportsFormValues;
  setFormValues: React.Dispatch<React.SetStateAction<ReportsFormValues>>;
}

const DateBox = ({ formValues, setFormValues }: DateRangePickerProps) => {
  const reportsQueryOptions = useAppSelector(selectReportsQueryOptions);
  const handlePeriodChange = (value: any) => {
    const selectedPeriod = value.props.value;

    if (selectedPeriod) {
      const startDate = reportsQueryOptions.periodPresets.find(
        (preset) => preset.title === selectedPeriod
      )?.period.from;

      const endDate = reportsQueryOptions.periodPresets.find(
        (preset) => preset.title === selectedPeriod
      )?.period.to;

      const newFormValues = { ...formValues };
      newFormValues.startDate = startDate;
      newFormValues.endDate = endDate;
      newFormValues.period = selectedPeriod;

      setFormValues(newFormValues);
    }
  };
  return (
    <Container
      maxWidth={false}
      sx={{
        background: "#f4f4f4",
        margin: 0,
        mt: "1rem",
        padding: "1rem",
        maxWidth: "940px",
      }}
    >
      <Box display="flex" alignItems="start" mb="0.5rem">
        <Box sx={{ mr: "0.5rem" }}>
          <Select
            label="Period"
            id="period"
            name="period"
            onChange={(event: any, value: any) => handlePeriodChange(value)}
            value={formValues.period}
            minWidth={180}
            options={
              reportsQueryOptions
                ? [...reportsQueryOptions.periodPresets].map((periodPreset) => {
                    return {
                      label:
                        PeriodPresetTypes[
                          periodPreset.title as keyof typeof PeriodPresetTypes
                        ],
                      value: periodPreset.title,
                    };
                  })
                : []
            }
          />
        </Box>
        <Box sx={{ mr: "0.5rem" }}>
          <FormControl
            sx={{
              minWidth: 180,
              maxWidth: 180,
              background: "white",
            }}
            size="small"
          >
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              name="startDate"
              value={formValues.startDate ? dayjs(formValues.startDate) : null}
              onChange={(value) => {
                const newFormValues = { ...formValues };

                newFormValues.startDate = dayjs(value).format("YYYY-MM-DD");
                newFormValues.period = "";

                setFormValues(newFormValues);
              }}
              label="Start date"
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl
            sx={{
              minWidth: 180,
              maxWidth: 180,
              background: "white",
            }}
            size="small"
          >
            <DatePicker
              slotProps={{ textField: { size: "small" } }}
              name="endDate"
              value={formValues.endDate ? dayjs(formValues.endDate) : null}
              onChange={(value) => {
                const newFormValues = { ...formValues };

                newFormValues.endDate = dayjs(value).format("YYYY-MM-DD");
                newFormValues.period = "";

                setFormValues(newFormValues);
              }}
              label="End date"
            />
          </FormControl>
        </Box>
      </Box>
      <Box display="flex" alignItems="start">
        <Box sx={{ mr: "0.5rem" }}>
          <Select
            label="Readings type"
            id="readingsType"
            name="readingsType"
            value={formValues.readingsType}
            onChange={(event: any, value: any) => {
              const newFormValues = { ...formValues };

              newFormValues.readingsType = value.props.value;

              setFormValues(newFormValues);
            }}
            minWidth={240}
            options={[
              { label: "---", value: 0 },
              ...(reportsQueryOptions
                ? [...reportsQueryOptions.readingTypes].map((readingType) => {
                    return {
                      label:
                        ReadingTypeTypes[
                          readingType as keyof typeof ReadingTypeTypes
                        ],
                      value: readingType,
                    };
                  })
                : []),
            ]}
          />
        </Box>
        <Select
          label="Sum columns"
          id="sumColumns"
          name="sumColumns"
          value={formValues.sumColumns}
          onChange={(event: any, value: any) => {
            const newFormValues = { ...formValues };

            newFormValues.sumColumns = value.props.value;

            setFormValues(newFormValues);
          }}
          minWidth={200}
          options={[
            { label: "---", value: 0 },
            ...(reportsQueryOptions
              ? [...reportsQueryOptions.readingsSumType].map((sumType) => {
                  return {
                    label:
                      ReadingSumTypeTypes[
                        sumType as keyof typeof ReadingSumTypeTypes
                      ],
                    value: sumType,
                  };
                })
              : []),
          ]}
        />
      </Box>
    </Container>
  );
};

export default DateBox;
